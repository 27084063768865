import React, { useState } from "react";
import { Box, Form, Heading, Button, Anchor, Image, Text } from "../../components/elements";
import IconField from "../../components/fields/IconField";
import Logo from "../../components/Logo";
import data from "../../data/master/forgot.json";
import Axios from 'axios';
import { useNavigate } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ForgotPassword() {

    const navigate = useNavigate();

  
    const [email, setEmail] = useState("");

    const Success = (message) => {
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        });
      }
  
      const Error = (message) => {
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER
        });
      }


    const forgotPass = async () => {
        await Axios.post(`${process.env.REACT_APP_BASE_URL}admin/forgot-password`,{
            email
        })
        .then((res) => {
            Success(res.data.message);
        }).catch((e) => {
            Error(e.response.data.message);
        })
    }  

    return (
    <>
        <ToastContainer autoClose={1000} />
        <Box className="mc-auth">
            <Image
                src={ data?.pattern.src } 
                alt={ data?.pattern.alt }
                className="mc-auth-pattern" 
            />
            <Box className="mc-auth-group">
                <Logo 
                    src = { data?.logo.src }
                    alt = { data?.logo.alt }
                    href = { data?.logo.path }
                    className = "mc-auth-logo"
                />
                <Heading as="h4" className="mc-auth-title">{ data?.title }</Heading>
                <Form className="mc-auth-form" >
                     <TextField
                       id="filled-basic" 
                       label="Email"
                       type="text"
                       variant="filled"
                       sx={{width:"100%",mb:"1.5rem"}}
                       onChange={(e) => setEmail(e.target.value)}
                     />

        
                   <Button onClick={forgotPass} className={`mc-auth-btn ${data?.button.fieldSize}`} type={ data?.button.type }>{ data?.button.text }</Button>
                    {/* <Anchor className="mc-auth-forgot" href={ data?.forgot.path }>{ data?.forgot.text }</Anchor> */}
                   
                </Form>
            </Box>
        </Box>
    </>
    );
}