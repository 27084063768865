import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";

import TextField from "@mui/material/TextField";

import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { Modal, ModalClose, Sheet, Stack, Typography } from "@mui/joy";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function GradesList() {
  const [openEditGrade, setOpenEditGrade] = useState(false);

  const [editedGrade, setEditedGrade] = useState("");

  const [selectedGrade, setSelectedGrade] = useState("");

  const [grades, setGrades] = useState([]);

  const [callApi, setCallApi] = useState(false);

  const [warning, setWarning] = useState({
    text: "",
    status: "",
  });

  const [openWarning, setOpenWarning] = useState(false);
  const [selectedGradeActive, setSelectedGradeActive] = useState(0);

  const Error = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const fetchGrades = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}grade/get-all-grades`)
      .then((res) => {
        setGrades(res.data.Grades);
      })
      .catch((e) => {
        Error(e.response.data.message);
        setGrades([]);
      });
  };

  const editGrade = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}grade/edit-grade/${selectedGrade}`,
        { grade: editedGrade }
      )
      .then((res) => {
        setCallApi(!callApi);
      })
      .finally(() => {
        setOpenEditGrade(false);
      })
      .catch((e) => {
        Error(e.response.data.message);
      });
  };

  const changeStatus = async (grade_id, active) => {
    let FinalStatus;
    if (active === 0) {
      FinalStatus = 1;
    } else {
      FinalStatus = 0;
    }
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}grade/change-status`, {
        id: grade_id,
        active: FinalStatus,
      })
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {
        Error(e.response.data.message);
      });
  };

  const deleteGrade = async (grade_id, grade) => {
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}grade/delete-grade`, {
        id: grade_id,
        grade,
      })
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {
        Error(e.response.data.message);
      });
  };

  useEffect(() => {
    fetchGrades();
  }, [callApi]);

  const grade_data = {
    columns: [
      {
        label: "S.no",
        field: "s_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Board Name",
        field: "board_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Grade Name",
        field: "grade_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: grades?.map((grade, index) => {
      return {
        s_no: index + 1,
        board_name: grade.board.name,
        grade_name: grade.name,
        actions: (
          <td
            style={{ display: "flex", justifyContent: "start", width: "100%" }}
          >
            <button
              type="button"
              onClick={() => {
                setOpenEditGrade(true);
                setSelectedGrade(grade._id);
                setEditedGrade(grade.name);
              }}
              style={{
                width: "2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className="btn btn-success"
            >
              <i className="fas fa-edit"></i>
            </button>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setSelectedGrade({ id: grade._id });
                setSelectedGradeActive(grade.active);
                setWarning({
                  text: `Do you want to change the status to ${
                    grade.active === 0 ? `Active?` : `Inactive?`
                  }`,
                  type: "status",
                });
              }}
              style={{
                width: "4rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className={
                grade.active === 0 ? `btn btn-danger` : `btn btn-success`
              }
            >
              {grade.active === 0 ? `Inactive` : `Active`}
            </button>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setWarning({
                  text: `Do you want to delete the grade?`,
                  type: "delete",
                });
                setSelectedGrade({ name: grade.name, id: grade._id });
              }}
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="btn btn-danger"
            >
              <i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      };
    }),
  };

  return (
    <PageLayout>
      <ToastContainer autoClose={1000} />
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Breadcrumb title={"Grades List"}></Breadcrumb>
            <Link to="/add-grade">
              <Button className="btn btn-primary">Add Grade</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={12}>
                <Box className="mc-card">
                  <CardHeader title="Grades List" />
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={grade_data}
                          style={{
                            marginTop: "1rem",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>
        </Col>

        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openEditGrade}
          onClose={() => setOpenEditGrade(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "50%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={5}
            >
              Edit Grade
            </Typography>

            <Stack spacing={2} sx={{ alignItems: "center" }}>
              <TextField
                id="outlined-required"
                defaultValue=""
                placeholder="Enter Grade ..."
                multiline
                value={editedGrade}
                label="Grade"
                onChange={(e) => setEditedGrade(e.target.value)}
                sx={{ width: "100%" }}
              />
            </Stack>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <button
                disabled={editedGrade ? false : true}
                onClick={editGrade}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </Sheet>
        </Modal>

        {/* Warning Board Modal */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openWarning}
          onClose={() => setOpenWarning(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "30%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={5}
            >
              {warning.text}
            </Typography>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2rem",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (warning.type === "status") {
                    changeStatus(selectedGrade.id, selectedGradeActive);
                    setOpenWarning(false);
                  } else if (warning.type === "delete") {
                    deleteGrade(selectedGrade.id, selectedGrade.name);
                    setOpenWarning(false);
                  }
                }}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-success"
              >
                Yes
              </button>
              <button
                onClick={() => setOpenWarning(false)}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-danger"
              >
                No
              </button>
            </div>
          </Sheet>
        </Modal>
      </Row>
    </PageLayout>
  );
}
