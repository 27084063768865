import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb, DivideTitle } from "../../components";
import { CardHeader } from "../../components/cards";
import { LabelField,IconField,LabelTextarea, } from "../../components/fields";
import { Box, Item, Anchor, Text,Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { Table, Thead, Tbody, Th, Tr, Td } from "../../components/elements/Table";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { LoaderProvider } from "../../context/Preloader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function AddGrade() {

    const [value, setValue] = React.useState(dayjs(new Date()));

    const navigate = useNavigate();

    const [boards, setBoards] = useState("");

    const [board, setBoard] = useState("");
    const [grade, setGrade] = useState("");
    const [loading, setLoading] = useState(false);

    const Success = (message) => {
      toast.success(message, {
        position: toast.POSITION.TOP_CENTER
      });
    }

    const Error = (message) => {
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER
      });
    }

    const fetchBoards = async () => {
      setLoading(true);
      await axios.get(`${process.env.REACT_APP_BASE_URL}board/get-all-boards`)
      .then((res) => {
        setBoards(res.data.Boards);
      }).finally(() => {
        setLoading(false);
      }).catch((e) => {
      })
    }

    const addGrade = async () => {
      await axios.post(`${process.env.REACT_APP_BASE_URL}grade/add-grade`,{board,grade})
      .then((res) => {
        Success('Grade Added Successfully !');
        navigate('/grades-list');
      }).catch((e) => {
        Error(e.response.data.message);
      })
    }


    const handleChange = (newValue) => {
      setValue(newValue);
    };


    useEffect(() => {
      fetchBoards()
     }, [])

    
    return (
      <>
      {!loading?
      <>
        <PageLayout>
        <ToastContainer autoClose={1000} />
            <Row>
                <Col xl={12}>
                <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                    <Breadcrumb title={ "Add Grades" }>
                    </Breadcrumb>
                    <Link to="/grades-list"><Button className="btn btn-primary">View Grades</Button></Link>
                    </div>
                </Col>
                <Col xl={12}>
                    <Box className="mc-card">
                        <Row xs={1} md={1} xl={1}>

                              <Col xl={3}>
                                <div style={{marginTop:"2rem"}}>
                                <CardHeader title="Board Name" />
                                <FormControl sx={{width:"100%"}}>
                                  <InputLabel id="demo-simple-select-label">Board</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Board"
                                    onChange={(e) => setBoard(e.target.value)}
                                  >
                                      {boards.length>0 && boards.map((board) => {
                                      return(
                                      <MenuItem key={board._id} value={board._id}>{board.name}</MenuItem>
                                      )
                                    })}
                              
                                    
                                  </Select>

                                </FormControl>
                                </div>

                                </Col>
                                
                               <Col xl={3}>

                                <div style={{marginTop:"2rem"}}>
                                <CardHeader title="Grade Name" />
                                <TextField
                                  id="outlined-required"
                                  defaultValue=""
                                  sx={{width:"100%"}}
                                  onChange={(e) => setGrade(e.target.value)}
                                />
                                </div>

                                </Col>

                              


                                <Col xl={3}>
                                <div style={{display:"flex",width:"100%", justifyContent:"start", alignItems:"center" ,marginTop:"5.25rem"}}>
                                <Button onClick={addGrade} className="btn btn-primary">Add</Button>
                              </div>
                                </Col>


                            {/*
                            <Col>
                                <DivideTitle title="large size" className="mb-4 mt-3" />
                                <LabelField label="text field" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" />
                                <LabelField label="select option" fieldSize="w-100 h-lg" labelDir="label-col mb-4" option={["admin", "member", "client"]} />
                                <LabelTextarea label="long textarea" fieldSize="w-100 h-text-lg" labelDir="label-col"  />
                            </Col> */}
                        </Row>
                    </Box>
                </Col>
            </Row>
        </PageLayout>
        </>
       :
       <LoaderProvider loading={loading} /> 
       }                  
       </>
    )
}