import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";

import { Button } from "react-bootstrap";

import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { Modal, ModalClose, Sheet, Stack, Typography } from "@mui/joy";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ProfessionList() {
  const [openEditProfession, setOpenEditProfession] = useState(false);
  const [editedProfession, setEditedProfession] = useState("");

  const [selectedProfession, setSelectedProfession] = useState({
    name: "",
    id: "",
  });

  const [professions, setProfessions] = useState([]);
  const [callApi, setCallApi] = useState(false);

  const [warning, setWarning] = useState({
    text: "",
    status: "",
  });

  const [openWarning, setOpenWarning] = useState(false);

  const Error = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const editProfession = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}profession/edit-profession/${selectedProfession.id}`,
        { profession: editedProfession }
      )
      .then((res) => {
        setCallApi(!callApi);
      })
      .finally(() => {
        setOpenEditProfession(false);
      })
      .catch((e) => {
        Error(e.response.data.message);
      });
  };

  const deleteProfession = async (profession_id, profession) => {
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}profession/delete-profession`, {
        id: profession_id,
        profession,
      })
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {});
  };

  const fetchProfessions = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}profession/get-all-professions`)
      .then((res) => {
        setProfessions(res.data.Professions);
      })
      .catch((e) => {
        setProfessions([]);
      });
  };

  useEffect(() => {
    fetchProfessions();
  }, [callApi]);

  const professions_data = {
    columns: [
      {
        label: "S.no",
        field: "s_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Profession Name",
        field: "profession_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: professions?.map((profession, index) => {
      return {
        s_no: index + 1,
        profession_name: profession.name,
        actions: (
          <td
            style={{ display: "flex", justifyContent: "start", width: "100%" }}
          >
            <button
              type="button"
              onClick={() => {
                setOpenEditProfession(true);
                setEditedProfession(profession.name);
                setSelectedProfession({
                  name: profession.name,
                  id: profession._id,
                });
              }}
              style={{
                width: "2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className="btn btn-success"
            >
              <i className="fas fa-edit"></i>
            </button>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setWarning({
                  text: `Do you want to delete the profession?`,
                  type: "delete",
                });
                setSelectedProfession({
                  name: profession.name,
                  id: profession._id,
                });
              }}
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="btn btn-danger"
            >
              <i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      };
    }),
  };
  return (
    <PageLayout>
      <ToastContainer autoClose={1000} />
      <Row>
        <Col xl={12}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Breadcrumb title={"Professions List"}></Breadcrumb>
            <Link to="/add-profession">
              <Button>ADD PROFESSION</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={12}>
                <Box className="mc-card">
                  <CardHeader title="Professions List" />
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={professions_data}
                          style={{ marginTop: "1rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
              {/*
                            <Col>
                                <DivideTitle title="large size" className="mb-4 mt-3" />
                                <LabelField label="text field" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" />
                                <LabelField label="select option" fieldSize="w-100 h-lg" labelDir="label-col mb-4" option={["admin", "member", "client"]} />
                                <LabelTextarea label="long textarea" fieldSize="w-100 h-text-lg" labelDir="label-col"  />
                            </Col> */}
            </Row>
          </Box>
        </Col>

        {/* Edit Board Modal */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openEditProfession}
          onClose={() => setOpenEditProfession(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "30%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={5}
            >
              Edit Profession
            </Typography>

            <Stack spacing={2} sx={{ alignItems: "center" }}>
              <TextField
                id="outlined-required"
                defaultValue=""
                placeholder="Enter Profession ..."
                multiline
                label="Profession"
                value={editedProfession}
                onChange={(e) => setEditedProfession(e.target.value)}
                sx={{ width: "100%" }}
              />
            </Stack>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <button
                disabled={editedProfession ? false : true}
                onClick={editProfession}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </Sheet>
        </Modal>

        {/* Warning Profession Modal */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openWarning}
          onClose={() => setOpenWarning(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "30%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={5}
            >
              {warning.text}
            </Typography>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2rem",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (warning.type === "delete") {
                    deleteProfession(
                      selectedProfession.id,
                      selectedProfession.name
                    );
                    setOpenWarning(false);
                  }
                }}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-success"
              >
                Yes
              </button>
              <button
                onClick={() => setOpenWarning(false)}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-danger"
              >
                No
              </button>
            </div>
          </Sheet>
        </Modal>
      </Row>
    </PageLayout>
  );
}
