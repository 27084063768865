import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb, DivideTitle } from "../../components";
import { CardHeader } from "../../components/cards";
import { LabelField,IconField,LabelTextarea, } from "../../components/fields";
import { Box, Item, Anchor, Text,Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { Table, Thead, Tbody, Th, Tr, Td } from "../../components/elements/Table";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';

import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Link, useLocation } from "react-router-dom";




export default function AddGroupSession() {

    const [value, setValue] = React.useState(dayjs(new Date()));

    const [questionModal, setQuestionModal] = useState(false);
    const [physicsquestionModal, setPhysicsQuestionModal] = useState(false);

    


    const handleChange = (newValue) => {
      setValue(newValue);
    };
    
    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                    <Breadcrumb title={ "Add Group Session" }>
                    </Breadcrumb>
                    <Link to="/sessions-list"><Button className="btn btn-primary">View Group Sessions</Button></Link>
                    </div>
                </Col>
                <Col xl={12}>
                    <Box className="mc-card">
                        <Row xs={1} md={1} xl={1}>
                            

                              
                                <Col xl={2}>

                                <div style={{marginTop:"2rem"}}>
                                <CardHeader title="Aspirant" />
                                <TextField
                                  id="outlined-required"
                                  defaultValue=""
                                  sx={{width:"100%"}}
                                />
                                </div>


                                </Col>

                                <Col xl={2}>

                                    <div style={{marginTop:"2rem"}}>
                                    <CardHeader title="Qtr Month-YY name" />
                                    <TextField
                                      id="outlined-required"
                                      defaultValue=""
                                      sx={{width:"100%"}}
                                    />
                                    </div>


                                    </Col>

                                    <Col xl={2}>
                                    <div style={{marginTop:"2rem"}}>
                                    <CardHeader title="Level" />
                                    <FormControl sx={{width:150}}>
                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    >
                                    <MenuItem value={10}>Easy</MenuItem>
                                    <MenuItem value={10}>Medium</MenuItem>
                                    <MenuItem value={10}>Hard</MenuItem>

                                    </Select>

                                    </FormControl>
                                    </div>
                                    </Col>

                                    <Col xl={2}>

                                    <div style={{marginTop:"2rem"}}>
                                    <CardHeader title="Batch" />
                                    <TextField
                                      id="outlined-required"
                                      defaultValue=""
                                      sx={{width:"100%"}}
                                    />
                                    </div>


                                    </Col>

                                
                                <Col xl={2}>
                                <div style={{marginTop:"2rem"}}>
                                <CardHeader title="Session Link" />
                                <TextField
                                  id="outlined-required"
                                  defaultValue=""
                                  sx={{width:"100%"}}
                                />
                                </div>

                                </Col>


                                <Col xl={2}>

                                <div style={{marginTop:"2rem"}}>
                                <CardHeader title="Password" />
                                <TextField
                                  id="outlined-required"
                                  defaultValue=""
                                  sx={{width:"100%"}}
                                />
                                </div>

                                </Col>

                                <Col xl={2}>
                                <div style={{marginTop:"2rem"}}>
                                <CardHeader title="Date" />
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                  inputFormat="MM/DD/YYYY"
                                  value={value}
                                  onChange={handleChange}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                                </LocalizationProvider>
                                </div>
                                </Col>

                                <Col xl={2}>
                                <div style={{marginTop:"2rem"}}>
                                <CardHeader title="Time" />
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                  value={value}
                                  onChange={handleChange}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                                </LocalizationProvider>
                                </div>
                                </Col>


                               
                                <Col xl={6}>
                                <div style={{marginTop:"2rem"}}>
                                <CardHeader title="Purpose" />
                                <TextField
                                 id="outlined-textarea"
                                 placeholder="Enter Session Purpose..."
                                 multiline
                                 sx={{width : "100%"}}
                               />
                                </div>
                                </Col>

                                <Col xl={2}>
                                <div style={{display:"flex",width:"100%", justifyContent:"start", alignItems:"center" ,marginTop:"5.25rem"}}>
                                <Link to="/sessions-list"><Button className="btn btn-primary">Add</Button></Link>
                              </div>
                                </Col>


                                
                             <Col xl={12} />
                         
                        </Row>
                    </Box>
                </Col>
            </Row>
        </PageLayout>
    )
}