import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import TextField from "@mui/material/TextField";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { LoaderProvider } from "../../context/Preloader";

export default function EditSchool() {
  const location = useLocation();

  const School = location.state.School;

  const navigate = useNavigate();

  const [boards, setBoards] = useState([]);

  const [loading, setLoading] = useState(false);

  const [school, setSchool] = useState({
    name: School.name,
    email: School.email,
    phone: School.phone,
    address: School.address,
    board: School.board._id,
    contact_firstname: School.contact.first_name,
    contact_lastname: School.contact.last_name,
    contact_email: School.contact.email,
    contact_phone: School.contact.phone,
  });

  const fetchBoards = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}board/get-all-boards`)
      .then((res) => {
        setBoards(res.data.Boards);
      })
      .catch((e) => {});
  };

  const Success = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const Error = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  let name, value;

  const inputHandler = (e) => {
    name = e.target.name;
    value = e.target.value;

    setSchool({ ...school, [name]: value });
  };

  const editSchool = async () => {
    await axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}school/edit-school/${School._id}`,
        school
      )
      .then((res) => {
        Success("School Edited Successfully !");
        navigate("/schools-list");
      })
      .catch((e) => {
        Error(e.response.data.message);
      });
  };

  useEffect(() => {
    fetchBoards();
  }, []);

  return (
    <>
      {!loading ? (
        <>
          <PageLayout>
            <ToastContainer autoClose={1000} />
            <Row>
              <Col xl={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Breadcrumb title={"Add School"}></Breadcrumb>
                  <Link to="/schools-list">
                    <Button className="btn btn-primary">View Schools</Button>
                  </Link>
                </div>
              </Col>
              <Col xl={12}>
                <Box className="mc-card">
                  <CardHeader title="School Details" />
                  <Row xs={1} md={1} xl={1}>
                    <Col xl={4}>
                      <CardHeader title="School Name" />
                      <TextField
                        id="outlined-required"
                        defaultValue=""
                        placeholder="Enter School Name"
                        name="name"
                        value={school.name}
                        onChange={inputHandler}
                        sx={{ width: "100%" }}
                      />
                    </Col>

                    <Col xl={3}>
                      <CardHeader title="Select Board" />
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">
                          Board
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Board"
                          name="board"
                          value={school.board}
                          onChange={inputHandler}
                        >
                          {boards.length > 0 &&
                            boards.map((board) => {
                              return (
                                <MenuItem key={board._id} value={board._id}>
                                  {board.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Col>

                    <Col xl={4}>
                      <CardHeader title="School Email" />
                      <TextField
                        id="outlined-required"
                        type="email"
                        defaultValue=""
                        name="email"
                        value={school.email}
                        onChange={inputHandler}
                        placeholder="Enter School Email"
                        sx={{ width: "100%" }}
                      />
                    </Col>

                    <Col xl={6}>
                      <CardHeader title="School Phone" />
                      <TextField
                        id="outlined-required"
                        defaultValue=""
                        placeholder="000000000"
                        name="phone"
                        value={school.phone}
                        onChange={inputHandler}
                        sx={{ width: "100%" }}
                      />
                    </Col>

                    <Col xl={12}>
                      <CardHeader title="School Address" />
                      <TextField
                        id="outlined-required"
                        defaultValue=""
                        placeholder="Enter School Address"
                        name="address"
                        value={school.address}
                        onChange={inputHandler}
                        sx={{ width: "100%" }}
                        multiline
                      />
                    </Col>

                    <CardHeader title="Contact Person Details" />

                    <Col xl={6}>
                      <CardHeader title="First Name" />
                      <TextField
                        id="outlined-required"
                        defaultValue=""
                        name="contact_firstname"
                        value={school.contact_firstname}
                        onChange={inputHandler}
                        placeholder="Enter Your First Name"
                        sx={{ width: "100%" }}
                      />
                    </Col>

                    <Col xl={6}>
                      <CardHeader title="Last Name" />
                      <TextField
                        id="outlined-required"
                        defaultValue=""
                        name="contact_lastname"
                        value={school.contact_lastname}
                        onChange={inputHandler}
                        placeholder="Enter Your Last Name"
                        sx={{ width: "100%" }}
                      />
                    </Col>

                    <Col xl={6}>
                      <CardHeader title="Your Email" />
                      <TextField
                        type="email"
                        id="outlined-required"
                        defaultValue=""
                        name="contact_email"
                        value={school.contact_email}
                        onChange={inputHandler}
                        placeholder="Enter Your Email"
                        sx={{ width: "100%" }}
                      />
                    </Col>

                    <Col xl={6}>
                      <CardHeader title="Your Phone" />
                      <TextField
                        id="outlined-required"
                        defaultValue=""
                        name="contact_phone"
                        value={school.contact_phone}
                        onChange={inputHandler}
                        placeholder="Enter Your Phone"
                        sx={{ width: "100%" }}
                      />
                    </Col>

                    <Col xl={10}></Col>

                    <Col xl={2}>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          className="btn btn-primary"
                          onClick={(e) => {
                            e.preventDefault();
                            editSchool();
                          }}
                        >
                          Add
                        </Button>
                      </div>
                    </Col>

                    {/*
                            <Col>
                                <DivideTitle title="large size" className="mb-4 mt-3" />
                                <LabelField label="text field" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" />
                                <LabelField label="select option" fieldSize="w-100 h-lg" labelDir="label-col mb-4" option={["admin", "member", "client"]} />
                                <LabelTextarea label="long textarea" fieldSize="w-100 h-text-lg" labelDir="label-col"  />
                            </Col> */}
                  </Row>
                </Box>
              </Col>
            </Row>
          </PageLayout>
        </>
      ) : (
        <LoaderProvider loading={loading} />
      )}
    </>
  );
}
