import React, { useContext, useState, useRef } from "react";
import {
  LanguageDropdown,
  WidgetDropdown,
  ProfileDropdown,
} from "../components/header";
import { Button, Section, Box, Input } from "../components/elements";
import { DrawerContext } from "../context/Drawer";
import { ThemeContext } from "../context/Themes";
import { Logo } from "../components";
import data from "../data/master/header.json";

export default function Header() {
  const { drawer, toggleDrawer } = useContext(DrawerContext);
  const searchRef = useRef();
  const [scroll, setScroll] = useState("fixed");
  const [search, setSearch] = useState("");

  const admin = JSON.parse(localStorage.getItem("admin"));

  window.addEventListener("scroll", () => {
    if (window.pageYOffset > 0) setScroll("sticky");
    else setScroll("fixed");
  });

  document.addEventListener("mousedown", (event) => {
    if (!searchRef.current?.contains(event.target)) {
      setSearch("");
    }
  });

  return (
    <Section as="header" className={`mc-header ${scroll}`}>
      <Logo
        src={data?.logo.src}
        alt={data?.logo.alt}
        name={data?.logo.name}
        href={data?.logo.path}
      />
      <Box className="mc-header-group">
        <Box className="mc-header-left">
          <Button
            icon={data?.search.icon}
            className="mc-header-icon search"
            onClick={() => setSearch("show")}
          />
          <Button
            icon={drawer ? "menu_open" : "menu"}
            className="mc-header-icon toggle"
            onClick={toggleDrawer}
          />
        </Box>
        <Box className="mc-header-right">
          <ProfileDropdown
            name={"GS Admin"}
            image={data.profile.image}
            dropdown={data.profile.dropdown}
          />
        </Box>
      </Box>
    </Section>
  );
}
