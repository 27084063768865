import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { MDBDataTable } from "mdbreact";

import axios from "axios";
import { useEffect } from "react";

export default function Enquiries() {
  const [enquiries, setEnquiries] = useState([]);

  const data = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Phone",
        field: "phone",
        sort: "asc",
        width: 270,
      },
      {
        label: "Issue",
        field: "issue",
        sort: "asc",
        width: 200,
      },
      {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 100,
      },
      {
        label: "Session",
        field: "session",
        sort: "asc",
        width: 150,
      },
    ],
    rows: enquiries?.map((enquiry) => {
      return {
        name: enquiry.name,
        description: enquiry.description,
        phone: enquiry.phone,
        issue: enquiry.issue,
        session: enquiry.session,
      };
    }),
  };

  const fetchEnquiries = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}enquiry/get-all-enquiries`)
      .then((res) => {
        setEnquiries(res.data);
      })
      .catch((e) => {
        setEnquiries([]);
      });
  };

  useEffect(() => {
    fetchEnquiries();
  }, []);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Breadcrumb title={"Manage Enquiries"}></Breadcrumb>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={12}>
                <Box className="mc-card">
                  <CardHeader title="Manage Enquiries" />
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={data}
                          style={{ marginTop: "1rem" }}
                        />
                        {/* <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th scope="col">
                                          S.no
                                        </th>
                                        <th scope="col">Title</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Time</th>
                                        <th scope="col">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <th scope="row">&nbsp; 1</th>
                                        <td>
                                        Notification 1
                                        </td>
                                        <td>Notification Description</td>
                                        <td>30/12/2022</td>
                                        <td>01:00pm</td>
                                    
                                        
                                        <td style={{display:"flex",justifyContent:"space-evenly",width:"100%"}}>
                                          <button type="button" style={{width:"4rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"0.5rem"}} className="btn btn-danger">Inactive</button>
                                          <button type="button" style={{width:"2rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center"}} className="btn btn-danger"><i className="fas fa-trash"></i></button>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row">&nbsp; 2</th>
                                        <td>
                                        Notification 2
                                        </td>
                                        <td>Notification Description</td>
                                        <td>30/12/2022</td>
                                        <td>01:00pm</td>
                                    
                                        
                                        <td style={{display:"flex",justifyContent:"space-evenly",width:"100%"}}>
                                          <button type="button" style={{width:"4rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"0.5rem"}} className="btn btn-danger">Inactive</button>
                                          <button type="button" style={{width:"2rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center"}} className="btn btn-danger"><i className="fas fa-trash"></i></button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table> */}
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>
        </Col>
      </Row>
    </PageLayout>
  );
}
