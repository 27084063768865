import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import TextField from "@mui/material/TextField";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export default function EditPackage() {
  const navigate = useNavigate();

  const location = useLocation();

  const PACKAGE = location.state.Package;

  const [boards, setBoards] = useState("");
  const [grades, setGrades] = useState("");
  const [loading, setLoading] = useState(false);

  const Success = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const Error = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const [packages, setPackages] = useState({
    name: PACKAGE.name && PACKAGE.name,
    boardId: PACKAGE.board && PACKAGE.board._id,
    gradeId: PACKAGE.grade && PACKAGE.grade._id,
    image: PACKAGE.image ? PACKAGE.image : "",
    description: PACKAGE.description && PACKAGE.description,
    price: PACKAGE.price && PACKAGE.price,
  });

  const fetchBoards = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}board/get-all-boards`)
      .then((res) => {
        setBoards(res.data.Boards);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((e) => {
        setBoards([]);
      });
  };

  const fetchGrades = async () => {
    if (packages.boardId) {
      await axios
        .get(
          `${process.env.REACT_APP_BASE_URL}grade/get-grades-by-board/${packages.boardId}`
        )
        .then((res) => {
          setGrades(res.data.grades);
        })
        .catch((e) => {
          setGrades([]);
        });
    }
  };

  const EditPackages = async () => {
    await axios
      .put(
        `${process.env.REACT_APP_BASE_URL}package/edit-package/${PACKAGE._id}`,
        packages
      )
      .then((res) => {
        Success("Package Added Successfully !");
        navigate("/package-list");
      })
      .catch((e) => {
        Error(e.response.data.message);
      });
  };

  useEffect(() => {
    fetchBoards();
  }, []);

  useEffect(() => {
    fetchGrades();
  }, [packages.boardId]);

  return (
    <PageLayout>
      <ToastContainer autoClose={1000} />
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Breadcrumb title={"Add Package"}></Breadcrumb>
            <Link to="/package-list">
              <Button className="btn btn-primary">View Packages</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Package Name" />
                  <TextField
                    id="outlined-required"
                    defaultValue=""
                    value={packages.name}
                    placeholder="Enter Package Name"
                    sx={{ width: "100%" }}
                    onChange={(e) =>
                      setPackages({ ...packages, name: e.target.value })
                    }
                  />
                </div>
              </Col>

              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Board Name" />
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">Board</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Board"
                      value={packages.boardId}
                      onChange={(e) =>
                        setPackages({ ...packages, boardId: e.target.value })
                      }
                    >
                      {boards.length > 0 &&
                        boards.map((board) => {
                          return (
                            <MenuItem key={board._id} value={board._id}>
                              {board.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Grade" />
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">Grade</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Grade"
                      value={packages.gradeId}
                      disabled={packages.boardId ? false : true}
                      onChange={(e) =>
                        setPackages({ ...packages, gradeId: e.target.value })
                      }
                    >
                      {grades.length > 0 &&
                        grades.map((grade) => {
                          return (
                            <MenuItem key={grade._id} value={grade._id}>
                              {grade.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Description" />
                  <TextField
                    id="outlined-required"
                    defaultValue=""
                    value={packages.description}
                    placeholder="Enter Description"
                    sx={{ width: "100%" }}
                    onChange={(e) =>
                      setPackages({ ...packages, description: e.target.value })
                    }
                  />
                </div>
              </Col>

              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Price" />
                  <TextField
                    id="outlined-required"
                    defaultValue=""
                    value={packages.price}
                    placeholder="Enter Price of Package"
                    sx={{ width: "100%" }}
                    onChange={(e) =>
                      setPackages({ ...packages, price: e.target.value })
                    }
                  />
                </div>
              </Col>

              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Choose Image" />
                  <TextField
                    id="required"
                    type="file"
                    defaultValue=""
                    placeholder="Enter Price of Package"
                    sx={{ width: "100%" }}
                  />
                </div>
              </Col>

              <Col xl={2}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      EditPackages();
                    }}
                  >
                    Edit
                  </Button>
                </div>
              </Col>

              {/*
                            <Col>
                                <DivideTitle title="large size" className="mb-4 mt-3" />
                                <LabelField label="text field" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" />
                                <LabelField label="select option" fieldSize="w-100 h-lg" labelDir="label-col mb-4" option={["admin", "member", "client"]} />
                                <LabelTextarea label="long textarea" fieldSize="w-100 h-text-lg" labelDir="label-col"  />
                            </Col> */}
            </Row>
          </Box>
        </Col>
      </Row>
    </PageLayout>
  );
}
