import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import PageLayout from "../../layouts/PageLayout";

import TextField from "@mui/material/TextField";

import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box, Button } from "../../components/elements";

export default function AddQualification() {
  const navigate = useNavigate();
  const [qualification, setQualification] = useState("");
  const Success = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const Error = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const addQualification = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}qualification/add-qualification`,
        {
          qualification,
        }
      )
      .then((res) => {
        Success("Qualification Added Successfully !");
        navigate("/qualification-list");
      })
      .catch((e) => {
        Error(e.response.data.message);
      });
  };

  return (
    <PageLayout>
      <ToastContainer autoClose={1000} />
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Breadcrumb title={"Add Qualification"}></Breadcrumb>
            <Link to="/qualification-list">
              <Button className="btn btn-primary">View Qualification</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={3}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Qualification Name" />
                  <TextField
                    id="outlined-required"
                    value={qualification}
                    placeholder="Enter Qualification Name"
                    sx={{ width: "100%" }}
                    onChange={(e) => setQualification(e.target.value)}
                  />
                </div>
              </Col>

              <Col xl={3}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    alignItems: "center",
                    marginTop: "5.25rem",
                  }}
                >
                  <Button
                    className="btn btn-primary"
                    onClick={addQualification}
                  >
                    Add
                  </Button>
                </div>
              </Col>
            </Row>
          </Box>
        </Col>
      </Row>
    </PageLayout>
  );
}
