import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { Box } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { MDBDataTable } from "mdbreact";
import { Modal, ModalClose, Sheet, Stack, Typography } from "@mui/joy";
import Axios from "axios";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export default function UsersAssigned() {
  const [openViewStudent, setOpenViewStudent] = useState(false);

  const [students, setStudents] = useState([]);

  const [callApi, setCallApi] = useState(false);

  const [selectedStudent, setSelectedStudent] = useState("");

  const [searchParams] = useSearchParams();

  const TestId = searchParams.get("id");

  const Error = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const [warning, setWarning] = useState({
    text: "",
    status: "",
  });

  const [openWarning, setOpenWarning] = useState(false);

  const fetchBoards = async () => {
    await Axios.get(`${process.env.REACT_APP_BASE_URL}board/get-all-boards`)
      .then((res) => {})
      .catch((e) => {});
  };

  const fetchGrades = async () => {
    await Axios.get(`${process.env.REACT_APP_BASE_URL}grade/get-all-grades`)
      .then((res) => {})
      .catch((e) => {});
  };

  const deleteStudent = async (student) => {
    await Axios.delete(
      `${process.env.REACT_APP_BASE_URL}test/${TestId}/remove-user/${student._id}`
    )
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {
        Error(e.response.data.message);
      });
  };

  const changeStatus = async (student) => {
    await Axios.put(
      `${process.env.REACT_APP_BASE_URL}student/toggle-active/${student._id}`
    )
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {});
  };

  const getTestStudents = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}test/get-users/${TestId}`)
      .then((res) => {
        setStudents(res.data);
      })
      .catch((e) => {
        Error(e.response.data.message);
      });
  };

  const student_data = {
    columns: [
      {
        label: <>S.no&nbsp;</>,
        field: "s_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "First Name",
        field: "first_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Last Name",
        field: "last_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 150,
      },
      {
        label: "Phone",
        field: "phone",
        sort: "asc",
        width: 200,
      },
      {
        label: "Board",
        field: "board",
        sort: "asc",
        width: 100,
      },
      {
        label: "School",
        field: "school",
        sort: "asc",
        width: 150,
      },
      {
        label: "Aspirant",
        field: "aspirant",
        sort: "asc",
        width: 100,
      },
      {
        label: "State",
        field: "state",
        sort: "asc",
        width: 100,
      },
      {
        label: "District",
        field: "district",
        sort: "asc",
        width: 100,
      },
      {
        label: "Grade",
        field: "grade",
        sort: "asc",
        width: 100,
      },
      {
        label: "Marks Obtained",
        field: "marks",
        sort: "asc",
        width: 50,
      },
      {
        label: "Completion Status",
        field: "status",
        sort: "asc",
        width: 50,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: students.map((student, index) => {
      return {
        s_no: <th scope="row">&nbsp;{index + 1}</th>,
        first_name: student.first_name,
        last_name: student.last_name,
        email: student.email,
        board: student.board,
        school: student.school,
        phone: student.mobile,
        aspirant: student.aspirant,
        state: student.state ? student.state : `NA`,
        district: student.district ? student.district : `NA`,
        grade: student.grade,
        marks: student.marks,
        status: <>{student.completion_status === 1 ? <>Yes</> : <>No</>}</>,
        actions: (
          <td
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            {student.marks === 0 && (
              <button
                type="button"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "1.6rem",
                }}
                className="btn btn-primary"
              >
                Reassign
              </button>
            )}
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setWarning({
                  text: `Do you want to remove ${student.first_name} from the Test?`,
                  type: "delete",
                });
                setSelectedStudent(student);
              }}
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="btn btn-danger"
            >
              <i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      };
    }),
  };

  useEffect(() => {
    fetchBoards();
    fetchGrades();
    getTestStudents();
  }, [callApi]);

  return (
    <>
      <PageLayout>
        <ToastContainer autoClose={1000} />
        <Row>
          <Col xl={12}>
            <Breadcrumb title={"Examinees List"}></Breadcrumb>
          </Col>
          <Col xl={12}>
            <Box className="mc-card">
              <Row xs={1} md={1} xl={1}>
                <Col xl={12}>
                  {/* <Row style={{marginBottom:"1rem"}}>
                             <Col xl={2}>
                        <CardHeader title="Select Board" />
                        <FormControl sx={{width:150}}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={filters.board}
                            onChange={(e) => setFilters({...filters, board : e.target.value})}
                          >
                            {boards.length > 0&& boards.map((board) => {
                              return (
                            <MenuItem value={board.name}>{board.name}</MenuItem>
                            )
                            })}

                            
                          </Select>
                            
                        </FormControl>
                            
                        </Col>
                            
                        <Col xl={2}>
                        <CardHeader title="Select Grade" />
                        <FormControl sx={{width:150}}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={filters.grade}
                            onChange={(e) => setFilters({...filters, grade : e.target.value})}
                          >
                            <MenuItem value={""}>None</MenuItem>
                            {grades.length > 0&& grades.map((grade) => {
                              return (
                            <MenuItem value={grade.name}>{grade.name}</MenuItem>
                            )
                            })}
                          </Select>
                            
                        </FormControl>
                            
                        </Col>
                            
                        <Col xl={2}>
                        <CardHeader title="Select Aspiration" />
                        <FormControl sx={{width:150}}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={filters.aspirant}
                            onChange={(e) => setFilters({...filters, aspirant : e.target.value})}
                          >
                            <MenuItem value={""}>None</MenuItem>
                            {aspirations.length > 0&& aspirations.map((aspiration) => {
                              return (
                            <MenuItem value={aspiration}>{aspiration}</MenuItem>
                            )
                            })}
                          </Select>
                            
                        </FormControl>
                        </Col>
                            
                        <Col xl={2}>
                        <CardHeader title="Select State" />
                        <FormControl sx={{width:150}}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={filters.state}
                            onChange={(e) => setFilters({...filters, state : e.target.value})}
                          >
                            <MenuItem value={""}>None</MenuItem>
                            {
                              states.length>0 && states.map((state) => {
                                return (
                                  <MenuItem value={state}>{state}</MenuItem>
                                )
                              })
                            }
                            
                            
                          </Select>
                            
                        </FormControl>
                        </Col>

                        <Col xl={2}>
                        <CardHeader title="Select District" />
                        <FormControl sx={{width:150}}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={filters.district}
                            onChange={(e) => setFilters({...filters, district : e.target.value})}
                          >
                            <MenuItem value={""}>None</MenuItem>
                            {
                              districts.length>0 && districts.map((district) => {
                                return (
                                  <MenuItem value={district}>{district}</MenuItem>
                                )
                              })
                            }
                          </Select>
                            
                        </FormControl>
                        </Col>
                            
                            
                        <Col xl={2}>
                        <div style={{display:"flex",width:"100%", justifyContent:"start", alignItems:"center" ,marginTop:"3.25rem"}}>
                        <Button className="btn btn-primary" onClick={(e) => {e.preventDefault(); fetchStudentsList()}} >Apply</Button>
                        </div>
                        </Col>

                        </Row> */}
                  <Box className="mc-card">
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <MDBDataTable
                            striped
                            bordered
                            small
                            data={student_data}
                            style={{
                              marginTop: "1rem",
                              display: "block",
                              overflowX: "auto",
                              whiteSpace: "nowrap",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Box>
                </Col>
              </Row>
            </Box>
          </Col>

          {/* View Student Modal */}
          <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={openViewStudent}
            onClose={() => setOpenViewStudent(false)}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "1000",
            }}
          >
            <Sheet
              variant="outlined"
              sx={{
                width: "50%",
                borderRadius: "md",
                p: 3,
                boxShadow: "lg",
                bgcolor: "#fff",
              }}
            >
              <ModalClose
                variant="outlined"
                sx={{
                  top: "calc(-1/4 * var(--IconButton-size))",
                  right: "calc(-1/4 * var(--IconButton-size))",
                  boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                  borderRadius: "50%",
                  bgcolor: "#eee",
                }}
              />
              <Typography
                id="basic-modal-dialog-title"
                component="h2"
                level="inherit"
                fontSize="1.25em"
                mb={1}
              >
                View Student
              </Typography>

              <Stack spacing={2} sx={{ alignItems: "center" }}>
                <div
                  style={{
                    width: "5.5rem",
                    height: "5.5rem",
                    borderRadius: "50%",
                    backgroundImage:
                      "url(https://images.hindustantimes.com/rf/image_size_800x600/HT/p2/2015/12/01/Pictures/_c34102da-9849-11e5-b4f4-1b7a09ed2cea.jpg)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    width: "100%",
                    padding: "2rem",
                    border: "1px solid #eee",
                    marginTop: "2rem",
                  }}
                >
                  <Col xl={6}>
                    <Typography
                      id="basic-modal-dialog-title"
                      component="h3"
                      level="inherit"
                      fontSize="1rem"
                      mb={2}
                    >
                      First Name :
                    </Typography>
                    <Typography
                      id="basic-modal-dialog-title"
                      component="h3"
                      level="inherit"
                      fontSize="1rem"
                      mb={2}
                    >
                      Last Name :
                    </Typography>
                    <Typography
                      id="basic-modal-dialog-title"
                      component="h3"
                      level="inherit"
                      fontSize="1rem"
                      mb={2}
                    >
                      Phone :
                    </Typography>

                    <Typography
                      id="basic-modal-dialog-title"
                      component="h3"
                      level="inherit"
                      fontSize="1rem"
                      mb={2}
                    >
                      Email :
                    </Typography>

                    <Typography
                      id="basic-modal-dialog-title"
                      component="h3"
                      level="inherit"
                      fontSize="1rem"
                      mb={2}
                    >
                      Board :
                    </Typography>
                    <Typography
                      id="basic-modal-dialog-title"
                      component="h3"
                      level="inherit"
                      fontSize="1rem"
                      mb={2}
                    >
                      School :
                    </Typography>

                    <Typography
                      id="basic-modal-dialog-title"
                      component="h3"
                      level="inherit"
                      fontSize="1rem"
                      mb={2}
                    >
                      Aspirant :
                    </Typography>
                    <Typography
                      id="basic-modal-dialog-title"
                      component="h3"
                      level="inherit"
                      fontSize="1rem"
                      mb={2}
                    >
                      Grade :
                    </Typography>
                  </Col>

                  <Col xl={6}>
                    <Typography
                      id="basic-modal-dialog-title"
                      component="h6"
                      level="inherit"
                      fontSize="1rem"
                      mb={2}
                    >
                      {selectedStudent.first_name}
                    </Typography>

                    <Typography
                      id="basic-modal-dialog-title"
                      component="h6"
                      level="inherit"
                      fontSize="1rem"
                      mb={2}
                    >
                      {selectedStudent.last_name}
                    </Typography>

                    <Typography
                      id="basic-modal-dialog-title"
                      component="h6"
                      level="inherit"
                      fontSize="1rem"
                      mb={2}
                    >
                      {selectedStudent.mobile}
                    </Typography>

                    <Typography
                      id="basic-modal-dialog-title"
                      component="h6"
                      level="inherit"
                      fontSize="1rem"
                      mb={2}
                    >
                      {selectedStudent.email}
                    </Typography>

                    <Typography
                      id="basic-modal-dialog-title"
                      component="h6"
                      level="inherit"
                      fontSize="1rem"
                      mb={2}
                    >
                      {selectedStudent.board}
                    </Typography>
                    <Typography
                      id="basic-modal-dialog-title"
                      component="h6"
                      level="inherit"
                      fontSize="1rem"
                      mb={2}
                    >
                      {selectedStudent.school}
                    </Typography>
                    <Typography
                      id="basic-modal-dialog-title"
                      component="h6"
                      level="inherit"
                      fontSize="1rem"
                      mb={2}
                    >
                      {selectedStudent.aspirant}
                    </Typography>

                    <Typography
                      id="basic-modal-dialog-title"
                      component="h6"
                      level="inherit"
                      fontSize="1rem"
                      mb={2}
                    >
                      {selectedStudent.grade}
                    </Typography>
                  </Col>
                </div>
              </Stack>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2rem",
                }}
              >
                <button
                  type="button"
                  onClick={() => setOpenViewStudent(false)}
                  style={{
                    width: "8rem",
                    fontSize: "0.8rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "0.5rem",
                  }}
                  className="btn btn-danger"
                >
                  Close
                </button>
              </div>
            </Sheet>
          </Modal>

          {/* Warning Student Modal */}
          <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={openWarning}
            onClose={() => setOpenWarning(false)}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "1000",
            }}
          >
            <Sheet
              variant="outlined"
              sx={{
                width: "30%",
                borderRadius: "md",
                p: 3,
                boxShadow: "lg",
                bgcolor: "#fff",
              }}
            >
              <ModalClose
                variant="outlined"
                sx={{
                  top: "calc(-1/4 * var(--IconButton-size))",
                  right: "calc(-1/4 * var(--IconButton-size))",
                  boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                  borderRadius: "50%",
                  bgcolor: "#eee",
                }}
              />
              <Typography
                id="basic-modal-dialog-title"
                component="h2"
                level="inherit"
                fontSize="1.25em"
                mb={5}
              >
                {warning.text}
              </Typography>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "2rem",
                }}
              >
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (warning.type === "status") {
                      changeStatus(selectedStudent);
                      setOpenWarning(false);
                    } else if (warning.type === "delete") {
                      deleteStudent(selectedStudent);
                      setOpenWarning(false);
                    }
                  }}
                  type="button"
                  style={{
                    width: "8rem",
                    fontSize: "0.8rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "0.5rem",
                  }}
                  className="btn btn-success"
                >
                  Yes
                </button>
                <button
                  onClick={() => setOpenWarning(false)}
                  type="button"
                  style={{
                    width: "8rem",
                    fontSize: "0.8rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "0.5rem",
                  }}
                  className="btn btn-danger"
                >
                  No
                </button>
              </div>
            </Sheet>
          </Modal>
        </Row>
      </PageLayout>
    </>
  );
}
