import { ThemeProvider } from "./context/Themes";
import { LoaderProvider } from "./context/Preloader";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Documentation, ChangeLog, Error } from "./pages/supports";
import {
  Avatars,
  Alerts,
  Buttons,
  Charts,
  Tables,
  Fields,
  Headings,
  Colors,
} from "./pages/blocks";
import {
  Ecommerce,
  Analytics,
  CRM,
  ForgotPassword,
  Register,
  Login,
  UserList,
  UserProfile,
  MyAccount,
  ProductList,
  ProductView,
  ProductUpload,
  InvoiceList,
  InvoiceDetails,
  OrderList,
  Message,
  Notification,
  BlankPage,
  Settings,
} from "./pages/master";
import AddBoard from "./pages/master/AddBoard";
import AddSchool from "./pages/master/AddSchool";
import AddGrade from "./pages/master/AddGrade";
import AddSubject from "./pages/master/AddSubject";
import AddChapter from "./pages/master/AddChapter";
import AddQuestion from "./pages/master/AddQuestion";
import QuestionBank from "./pages/master/QuestionBank";
import AddTest from "./pages/master/AddTest";
import TestsList from "./pages/master/TestsList";
import AddTestType from "./pages/master/AddTestType";
import TestTypesList from "./pages/master/TestTypesList";
import AddPackage from "./pages/master/AddPackage";
import PackageList from "./pages/master/PackageList";
import AddCounselling from "./pages/master/AddCounselling";
import CounsellingList from "./pages/master/CounsellingList";
import BoardsList from "./pages/master/BoardsList";
import SchoolsList from "./pages/master/SchoolsList";
import GradesList from "./pages/master/GradesList";
import SubjectsList from "./pages/master/SubjectsList";
import ChaptersList from "./pages/master/ChaptersList";
import StudentsList from "./pages/master/StudentsList";
import CounsellorsList from "./pages/master/CounsellorsList";
import AssignStudents from "./pages/master/AssignStudents";
import UsersAssigned from "./pages/master/UsersAssigned";
import AddCounsellingType from "./pages/master/AddCounsellingType";
import CounsellingTypesList from "./pages/master/CounsellingTypesList";
import Notifications from "./pages/master/Notifications";
import Enquiries from "./pages/master/Enquiries";
import AddUpcomingSession from "./pages/master/AddUpcomingSession";
import UpcomingSessionsList from "./pages/master/GroupSessionsList";
import AddNotifications from "./pages/master/AddNotifications";
import OneToOne from "./pages/master/OnetoOneList";
import GroupCounselling from "./pages/master/GroupCounselling";
import AddGroupSession from "./pages/master/AddGroupSession";
import GroupSessionsList from "./pages/master/GroupSessionsList";
import AddBatch from "./pages/master/AddBatch";
import BatchList from "./pages/master/BatchList";
import AddQualification from "./pages/master/AddQualification";
import SchoolLogin from "./pages/master/SchoolLogin";
import SchoolDashboard from "./pages/master/SchoolDashboard";
import SchoolStudentsList from "./pages/master/SchoolStudentsList";
import AddCounsellor from "./pages/master/AddCounsellor";
import EditCounsellor from "./pages/master/EditCounsellor";
import ResetPassword from "./pages/master/ResetPassword";
import EditSubject from "./pages/master/EditSubject";
import EditChapter from "./pages/master/EditChapter";
import EditSchool from "./pages/master/EditSchool";
import EditQuestion from "./pages/master/EditQuestion";
import EditTest from "./pages/master/EditTest";
import EditPackage from "./pages/master/EditPackage";
import EditSession from "./pages/master/EditSession";
import AddSubectArea from "./pages/master/AddSubjectArea";
import SubjectAreaList from "./pages/master/SubjectAreaList";
import AddProfession from "./pages/master/AddProfession";
import ProfessionList from "./pages/master/ProfessionList";
import AddFreeCareerTalk from "./pages/master/AddFreeCareerTalk";
import FreeCareerTalkList from "./pages/master/FreeCareerTalkList";
import EditBatch from "./pages/master/EditBatch";
import QualificationList from "./pages/master/QualificationList";
import AddStreams from "./pages/master/AddStreams";
import StreamsList from "./pages/master/StreamsList";

export default function App() {
  return (
    <ThemeProvider>
      <LoaderProvider>
        <BrowserRouter>
          <Routes>
            {/* master Pages */}
            <Route path="/ecommerce" element={<Ecommerce />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/crm" element={<CRM />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/user-list" element={<UserList />} />
            <Route path="/user-profile" element={<UserProfile />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/product-list" element={<ProductList />} />
            <Route path="/product-view" element={<ProductView />} />
            <Route path="/product-upload" element={<ProductUpload />} />
            <Route path="/invoice-list" element={<InvoiceList />} />
            <Route path="/invoice-details" element={<InvoiceDetails />} />
            <Route path="/order-list" element={<OrderList />} />
            <Route path="/message" element={<Message />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/blank-page" element={<BlankPage />} />

            <Route path="/add-board" element={<AddBoard />} />
            <Route path="/boards-list" element={<BoardsList />} />

            <Route path="/add-school" element={<AddSchool />} />
            <Route path="/edit-school" element={<EditSchool />} />
            <Route path="/schools-list" element={<SchoolsList />} />

            <Route path="/add-grade" element={<AddGrade />} />
            <Route path="/grades-list" element={<GradesList />} />

            <Route path="/add-subject" element={<AddSubject />} />
            <Route path="/edit-subject" element={<EditSubject />} />
            <Route path="/subjects-list" element={<SubjectsList />} />

            <Route path="/add-chapter" element={<AddChapter />} />
            <Route path="/edit-chapter" element={<EditChapter />} />
            <Route path="/chapters-list" element={<ChaptersList />} />

            <Route path="/add-question" element={<AddQuestion />} />
            <Route path="/edit-question" element={<EditQuestion />} />
            <Route path="/question-bank" element={<QuestionBank />} />

            <Route path="/add-test" element={<AddTest />} />
            <Route path="/edit-test" element={<EditTest />} />
            <Route path="/add-test-type" element={<AddTestType />} />
            <Route path="/test-types-list" element={<TestTypesList />} />
            <Route path="/tests-list" element={<TestsList />} />

            <Route path="/add-package" element={<AddPackage />} />
            <Route path="/edit-package" element={<EditPackage />} />
            <Route path="/package-list" element={<PackageList />} />

            <Route
              path="/add-counselling-type"
              element={<AddCounsellingType />}
            />
            <Route
              path="/counselling-types-list"
              element={<CounsellingTypesList />}
            />

            <Route path="/add-counselling" element={<AddCounselling />} />
            <Route path="/counselling-list" element={<CounsellingList />} />

            <Route path="/student-list" element={<StudentsList />} />

            <Route path="/add-counsellor" element={<AddCounsellor />} />
            <Route path="/edit-counsellor" element={<EditCounsellor />} />
            <Route path="/counsellors-list" element={<CounsellorsList />} />

            <Route path="/add-qualification" element={<AddQualification />} />
            <Route path="/edit-qualification" element={<EditCounsellor />} />
            <Route path="/qualification-list" element={<QualificationList />} />

            <Route path="/add-streams" element={<AddStreams />} />
            <Route path="/edit-qualification" element={<EditCounsellor />} />
            <Route path="/streams-list" element={<StreamsList />} />

            <Route path="/assign-students" exact element={<AssignStudents />} />
            <Route path="/users-assigned" exact element={<UsersAssigned />} />

            <Route path="/add-notifications" element={<AddNotifications />} />
            <Route path="/notifications" element={<Notifications />} />

            <Route path="/enquiries" element={<Enquiries />} />

            <Route path="/add-session" element={<AddUpcomingSession />} />
            <Route path="/edit-session" element={<EditSession />} />
            <Route path="/sessions-list" element={<UpcomingSessionsList />} />

            <Route path="/add-batch" element={<AddBatch />} />
            <Route path="/edit-batch" element={<EditBatch />} />
            <Route path="/batches-list" element={<BatchList />} />

            <Route path="/add-subject-area" element={<AddSubectArea />} />
            <Route path="/subject-area-list" element={<SubjectAreaList />} />

            <Route path="/add-profession" element={<AddProfession />} />
            <Route path="/profession-list" element={<ProfessionList />} />

            <Route path="/one-to-one-list" element={<OneToOne />} />

            <Route path="/add-career-talk" element={<AddFreeCareerTalk />} />
            <Route
              path="/free-career-talk-list"
              element={<FreeCareerTalkList />}
            />

            <Route path="/add-group-session" element={<AddGroupSession />} />
            <Route
              path="/group-sessions-list"
              element={<GroupSessionsList />}
            />

            <Route
              path="/group-counselling-list"
              element={<GroupCounselling />}
            />

            <Route path="/school-login" element={<SchoolLogin />} />

            <Route path="/school-dashboard" element={<SchoolDashboard />} />

            <Route
              path="/school-students-list"
              element={<SchoolStudentsList />}
            />

            {/* Blocks Pages */}
            <Route path="/headings" element={<Headings />} />
            <Route path="/buttons" element={<Buttons />} />
            <Route path="/avatars" element={<Avatars />} />
            <Route path="/colors" element={<Colors />} />
            <Route path="/charts" element={<Charts />} />
            <Route path="/tables" element={<Tables />} />
            <Route path="/fields" element={<Fields />} />
            <Route path="/alerts" element={<Alerts />} />

            {/* Supports Pages */}
            <Route path="*" element={<Error />} />
            <Route path="/" element={<Ecommerce />} />
            <Route path="/documentation" element={<Documentation />} />
            <Route path="/changelog" element={<ChangeLog />} />
          </Routes>
        </BrowserRouter>
      </LoaderProvider>
    </ThemeProvider>
  );
}
