import React from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import TextField from "@mui/material/TextField";

import { Link } from "react-router-dom";

export default function AddCounselling() {
  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Breadcrumb title={"Add Counselling"}></Breadcrumb>
            <Link to="/counselling-list">
              <Button className="btn btn-primary">View Counselling List</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={3}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title=" Counselling Type" />
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                    >
                      <MenuItem value={10}>One to One Counselling</MenuItem>
                      <MenuItem value={20}>GrowSharp Counselling</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={3}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Price" />
                  <TextField
                    id="outlined-required"
                    defaultValue=""
                    placeholder="Enter Price of Counselling"
                    sx={{ width: "100%" }}
                  />
                </div>
              </Col>

              <Col xl={3}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Choose Image" />
                  <TextField
                    id="required"
                    type="file"
                    defaultValue=""
                    placeholder="Enter Price of Package"
                    sx={{ width: "100%" }}
                  />
                </div>
              </Col>

              <Col xl={10}>
                <CardHeader title="Description" />
                <TextField
                  id="outlined-textarea"
                  placeholder="Enter Package Description..."
                  multiline
                  sx={{ width: "100%" }}
                />
              </Col>

              <Col xl={2}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    alignItems: "center",
                    marginTop: "3.25rem",
                  }}
                >
                  <Link to="/counselling-list">
                    <Button className="btn btn-primary">Add</Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Box>
        </Col>
      </Row>
    </PageLayout>
  );
}
