import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";

import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";

import Stack from "@mui/joy/Stack";

import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";

export default function CounsellingList() {
  const [openAssignPackage, setOpenAssignPackage] = useState(false);

  const counselling_data = {
    columns: [
      {
        label: "Counselling Type",
        field: "counselling_type",
        sort: "asc",
        width: 150,
      },
      {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 270,
      },
      {
        label: "Price",
        field: "price",
        sort: "asc",
        width: 200,
      },
      {
        label: "Package Assigned",
        field: "package_assigned",
        sort: "asc",
        width: 100,
      },
      {
        label: "Subscribed Users",
        field: "users",
        sort: "asc",
        width: 100,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: [
      {
        counselling_type: "Free Career Talk",
        image: (
          <td>
            <img
              alt="..."
              style={{
                maxWidth: "4.5rem",
                maxHeight: "4.5rem",
                objectFit: "cover",
              }}
              src="https://images.hindustantimes.com/rf/image_size_800x600/HT/p2/2015/12/01/Pictures/_c34102da-9849-11e5-b4f4-1b7a09ed2cea.jpg"
            ></img>
          </td>
        ),
        price: "200",
        package_assigned: (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Button
              className="btn btn-primary"
              size="small"
              onClick={() => setOpenAssignPackage(true)}
            >
              Assign
            </Button>
          </div>
        ),
        users: (
          <td>
            <div style={{ display: "flex" }}>
              <Link to="/users-assigned">
                <div
                  style={{
                    width: "2.5rem",
                    height: "2.5rem",
                    borderRadius: "50%",
                    color: "#fff",
                    background: "#ff6b6b",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  25
                </div>
              </Link>
            </div>
          </td>
        ),
        actions: (
          <td
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            <button
              type="button"
              style={{
                width: "2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className="btn btn-success"
            >
              .<i className="fas fa-edit"></i>
            </button>
            <button
              type="button"
              style={{
                width: "4rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "0.5rem",
              }}
              className="btn btn-danger"
            >
              Inactive
            </button>
            <button
              type="button"
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="btn btn-danger"
            >
              .<i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      },
      {
        counselling_type: "Group Counselling",
        image: (
          <td>
            <img
              alt="..."
              style={{
                maxWidth: "4.5rem",
                maxHeight: "4.5rem",
                objectFit: "cover",
              }}
              src="https://images.hindustantimes.com/rf/image_size_800x600/HT/p2/2015/12/01/Pictures/_c34102da-9849-11e5-b4f4-1b7a09ed2cea.jpg"
            ></img>
          </td>
        ),
        price: "350",
        package_assigned: (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Button
              className="btn btn-primary"
              size="small"
              onClick={() => setOpenAssignPackage(true)}
            >
              Assign
            </Button>
          </div>
        ),
        users: (
          <td>
            <div style={{ display: "flex" }}>
              <Link to="/users-assigned">
                <div
                  style={{
                    width: "2.5rem",
                    height: "2.5rem",
                    borderRadius: "50%",
                    color: "#fff",
                    background: "#ff6b6b",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  25
                </div>
              </Link>
            </div>
          </td>
        ),
        actions: (
          <td
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            <button
              type="button"
              style={{
                width: "2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className="btn btn-success"
            >
              .<i className="fas fa-edit"></i>
            </button>
            <button
              type="button"
              style={{
                width: "4rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "0.5rem",
              }}
              className="btn btn-danger"
            >
              Inactive
            </button>
            <button
              type="button"
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="btn btn-danger"
            >
              .<i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      },
    ],
  };

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Breadcrumb title={"Counselling List"}></Breadcrumb>
            <Link to="/add-counselling">
              <Button className="btn btn-primary">Add Counselling</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={12}>
                <Box className="mc-card">
                  <CardHeader title="Counselling List" />
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={counselling_data}
                          style={{ marginTop: "1rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>
        </Col>

        {/* Modal For Package Assign */}

        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openAssignPackage}
          onClose={() => setOpenAssignPackage(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "50%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={1}
            >
              Assign Package
            </Typography>

            <Stack spacing={2}>
              <div style={{ marginTop: "2rem" }}>
                <CardHeader title="Choose Package" />
                <Stack>
                  <FormControlLabel
                    value="360"
                    control={<Checkbox />}
                    label="360 Package"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="Test"
                    control={<Checkbox />}
                    label="Test Package"
                    labelPlacement="end"
                  />
                </Stack>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2rem",
                }}
              >
                <Button
                  className="btn btn-primary"
                  sx={{ width: "8rem" }}
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </Stack>
          </Sheet>
        </Modal>
      </Row>
    </PageLayout>
  );
}
