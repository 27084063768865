import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";

import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";

import Stack from "@mui/joy/Stack";

import TextField from "@mui/material/TextField";

import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";

import { MDBDataTable } from "mdbreact";
import axios from "axios";

export default function PackageList() {
  const [openAssignTest, setOpenAssignTest] = useState(false);

  const [types, setTypes] = useState([]);

  const [openAssignCounselling, setOpenAssignCounselling] = useState(false);

  const [testTypeIds, setTestTypeIds] = useState([]);

  const [warning, setWarning] = useState({
    text: "",
    status: "",
  });

  const [openWarning, setOpenWarning] = useState(false);

  const [selectedPackage, setSelectedPackage] = useState("");

  const [callApi, setCallApi] = useState(false);

  const [packages, setPackages] = useState([]);

  const [counsellingTypes, setCounsellingTypes] = useState([]);

  const [filteredTypes, setFilteredTypes] = useState([]);
  const [searchText, setSearchText] = useState("");

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      setTestTypeIds((prevIds) => [...prevIds, value]);
    } else {
      setTestTypeIds((prevIds) => prevIds.filter((id) => id !== value));
    }
  };

  const handleCounsellingCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      setCounsellingTypes((prevTypes) => [...prevTypes, value]);
    } else {
      setCounsellingTypes((prevTypes) =>
        prevTypes.filter((type) => type !== value)
      );
    }
  };

  const fetchPackages = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}package/get-all-packages`)
      .then((res) => {
        setPackages(res.data);
        if (res.data.test_types.length > 0) {
          setTestTypeIds((prevIds) => [
            ...prevIds,
            ...res.data.test_types.map((type) => type._id),
          ]);
        } else {
          setTestTypeIds([]);
        }
      })
      .catch((e) => {});
  };

  const changeStatus = async (pack) => {
    await axios
      .put(`${process.env.REACT_APP_BASE_URL}package/toggle-active/${pack._id}`)
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {});
  };

  const deletePackage = async (pack) => {
    await axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}package/remove-package/${pack._id}`
      )
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {});
  };

  const fetchTests = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}test-type/test-types`)
      .then((res) => {
        setTypes(res.data.TestType);
        setFilteredTypes(res.data.TestType);
      })
      .catch((e) => {
        setTypes([]);
      });
  };

  const EditPackage = async () => {
    await axios
      .put(
        `${process.env.REACT_APP_BASE_URL}package/edit-package/${selectedPackage._id}`,
        {
          name: selectedPackage.name,
          boardId: selectedPackage.board._id,
          gradeId: selectedPackage.grade._id,
          image: selectedPackage.image,
          description: selectedPackage.description,
          price: selectedPackage.price,
          testTypeIds: testTypeIds,
          counsellingTypes: counsellingTypes,
        }
      )
      .then((res) => {
        setOpenAssignTest(false);
        setOpenAssignCounselling(false);
        setCallApi(!callApi);
      })
      .catch((e) => {
        Error(e.response.data.message);
      });
  };

  // Function to handle search text change
  const handleSearchTextChange = (event) => {
    const value = event.target.value;
    setSearchText(value);

    // Filter the types based on the search text
    const filtered = types.filter((type) =>
      type.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredTypes(filtered);
  };

  useEffect(() => {
    fetchPackages();
    fetchTests();
  }, [callApi]);

  const packages_data = {
    columns: [
      {
        label: "S.no",
        field: "s_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Board",
        field: "board",
        sort: "asc",
        width: 150,
      },
      {
        label: "Grade",
        field: "grade",
        sort: "asc",
        width: 150,
      },
      {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 270,
      },
      {
        label: "Price",
        field: "price",
        sort: "asc",
        width: 200,
      },
      {
        label: "Description",
        field: "desc",
        sort: "asc",
        width: 200,
      },
      {
        label: "Assign Test",
        field: "assign_test",
        sort: "asc",
        width: 100,
      },
      {
        label: "Assign Counselling",
        field: "assign_counselling",
        sort: "asc",
        width: 100,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: packages.map((pack, length) => {
      return {
        s_no: length + 1,
        name: pack.name,
        board: pack.board.name,
        grade: pack.grade.name,
        desc: pack.description,
        image: (
          <td>
            <img
              alt="..."
              style={{
                maxWidth: "4.5rem",
                maxHeight: "4.5rem",
                objectFit: "cover",
              }}
              src={pack.image}
            ></img>
          </td>
        ),
        price: pack.price,
        assign_test: (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Button
              className="btn btn-primary"
              size="small"
              onClick={() => {
                setOpenAssignTest(true);
                setSelectedPackage(pack);
                setTestTypeIds(
                  pack.test_types.map((type) => {
                    return type._id;
                  })
                );
              }}
            >
              Assign
            </Button>
          </div>
        ),
        assign_counselling: (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Button
              className="btn btn-primary"
              size="small"
              onClick={() => {
                setOpenAssignCounselling(true);
                setSelectedPackage(pack);
                setTestTypeIds(
                  pack.test_types.map((type) => {
                    return type._id;
                  })
                );
                setCounsellingTypes(
                  pack.counselling_types.map((type) => {
                    return type.name;
                  })
                );
              }}
            >
              Assign
            </Button>
          </div>
        ),
        actions: (
          <td
            style={{ display: "flex", justifyContent: "start", width: "100%" }}
          >
            <Link to="/edit-package" state={{ Package: pack }}>
              <button
                type="button"
                style={{
                  width: "2rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "1.6rem",
                }}
                className="btn btn-success"
              >
                <i className="fas fa-edit"></i>
              </button>
            </Link>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setSelectedPackage(pack);
                setWarning({
                  text: `Do you want to change the status to ${
                    pack.active === 0 ? `Active?` : `Inactive?`
                  }`,
                  type: "status",
                });
              }}
              style={{
                width: "4rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className={
                pack.active === 0 ? `btn btn-danger` : `btn btn-success`
              }
            >
              {pack.active === 0 ? `Inactive` : `Active`}
            </button>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setWarning({
                  text: `Do you want to delete the package?`,
                  type: "delete",
                });
                setSelectedPackage(pack);
              }}
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="btn btn-danger"
            >
              <i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      };
    }),
  };

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Breadcrumb title={"Packages List"}></Breadcrumb>
            <Link to="/add-package">
              <Button className="btn btn-primary">Add Package</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={12}>
                <Box className="mc-card">
                  <CardHeader title="Package List" />
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={packages_data}
                          style={{ marginTop: "1rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>
        </Col>

        {/* Modal For Test Assign */}

        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openAssignTest}
          onClose={() => setOpenAssignTest(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "50%",
              maxHeight: "30rem",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
              overflowY: "scroll",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={1}
            >
              Assign Test
            </Typography>

            <Stack spacing={2}>
              <div style={{ marginTop: "2rem" }}>
                <CardHeader title="Choose Test" />

                <Stack spacing={2}>
                  <TextField
                    label="Search Test"
                    value={searchText}
                    onChange={handleSearchTextChange}
                  />
                  {filteredTypes.map((type) => (
                    <FormControlLabel
                      key={type._id}
                      value={type._id}
                      control={
                        <Checkbox
                          checked={testTypeIds.includes(type._id)}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label={type.name}
                      labelPlacement="end"
                    />
                  ))}
                </Stack>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2rem",
                }}
              >
                <Button
                  className="btn btn-primary"
                  sx={{ width: "8rem" }}
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    EditPackage();
                  }}
                >
                  Submit
                </Button>
              </div>
            </Stack>
          </Sheet>
        </Modal>

        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openAssignCounselling}
          onClose={() => setOpenAssignCounselling(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "50%",
              maxHeight: "30rem",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
              overflowY: "scroll",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={1}
            >
              Assign Counselling
            </Typography>

            <Stack spacing={2}>
              <div style={{ marginTop: "2rem" }}>
                <CardHeader title="Choose Test" />

                <Stack spacing={2}>
                  <TextField
                    label="Search Test"
                    value={searchText}
                    onChange={handleSearchTextChange}
                  />
                  <FormControlLabel
                    value={"one"}
                    control={
                      <Checkbox
                        checked={counsellingTypes.includes("one")}
                        onChange={handleCounsellingCheckboxChange}
                      />
                    }
                    label={"One To One Counselling"}
                    labelPlacement="end"
                  />

                  <FormControlLabel
                    value={"group"}
                    control={
                      <Checkbox
                        checked={counsellingTypes.includes("group")}
                        onChange={handleCounsellingCheckboxChange}
                      />
                    }
                    label={"Group Counselling"}
                    labelPlacement="end"
                  />
                </Stack>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2rem",
                }}
              >
                <Button
                  className="btn btn-primary"
                  sx={{ width: "8rem" }}
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    EditPackage();
                  }}
                >
                  Submit
                </Button>
              </div>
            </Stack>
          </Sheet>
        </Modal>
        {/* Warning Board Modal */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openWarning}
          onClose={() => setOpenWarning(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "30%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={5}
            >
              {warning.text}
            </Typography>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2rem",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (warning.type === "status") {
                    changeStatus(selectedPackage);
                    setOpenWarning(false);
                  } else if (warning.type === "delete") {
                    deletePackage(selectedPackage);
                    setOpenWarning(false);
                  }
                }}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-success"
              >
                Yes
              </button>
              <button
                onClick={() => setOpenWarning(false)}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-danger"
              >
                No
              </button>
            </div>
          </Sheet>
        </Modal>
      </Row>
    </PageLayout>
  );
}
