import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";

import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TextField } from "@mui/material";

export default function AddTestType() {
  const navigate = useNavigate();

  const [testType, setTestType] = useState("");
  const [price, setPrice] = useState("");

  const Success = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const Error = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const addTestType = async () => {
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}test-type/add-test-type`, {
        testType,
        price,
      })
      .then((res) => {
        Success("Test Type Added Successfully !");
        navigate("/test-types-list");
      })
      .catch((e) => {
        Error(e.response.data.message);
      });
  };

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Breadcrumb title={"Add Test Tyoe"}></Breadcrumb>
            <Link to="/test-types-list">
              <Button className="btn btn-primary">View Test Types</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={3}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Test Type Name" />
                  <TextField
                    id="outlined-required"
                    defaultValue=""
                    value={testType}
                    onChange={(e) => setTestType(e.target.value)}
                    placeholder="Enter Test Type"
                    sx={{ width: "100%" }}
                  />
                </div>
              </Col>
              <Col xl={3}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Price" />
                  <TextField
                    id="outlined-required"
                    defaultValue=""
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    placeholder="Enter Price"
                    sx={{ width: "100%" }}
                  />
                </div>
              </Col>

              <Col xl={3}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    alignItems: "center",
                    marginTop: "5.25rem",
                  }}
                >
                  <Button
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      addTestType();
                    }}
                  >
                    Add
                  </Button>
                </div>
              </Col>
            </Row>
          </Box>
        </Col>
      </Row>
    </PageLayout>
  );
}
