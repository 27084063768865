import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { Button } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { Modal, ModalClose, Sheet, Stack, Typography } from "@mui/joy";
export default function QualificationList() {
  const [openEditQualification, setOpenEditQualification] = useState(false);
  const [editedQualification, setEditedBoardQualification] = useState("");

  const [selectedQualification, setSelectedQualification] = useState({
    name: "",
    id: "",
  });
  const [selectedQualificationActive, setSelectedQualificationActive] =
    useState(0);

  const [Qualification, setQualification] = useState([]);
  const [callApi, setCallApi] = useState(false);

  const [warning, setWarning] = useState({
    text: "",
    status: "",
  });

  const [openWarning, setOpenWarning] = useState(false);

  const changeStatus = async (board_id, active) => {
    let FinalStatus;
    if (active === 0) {
      FinalStatus = 1;
    } else {
      FinalStatus = 0;
    }
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}qualification/change-status`, {
        id: board_id,
        active: FinalStatus,
      })
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {});
  };

  const editQualification = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}qualification/edit-qualification/${selectedQualification.id}`,
        { qualification: editedQualification }
      )
      .then((res) => {
        setCallApi(!callApi);
      })
      .finally(() => {
        setOpenEditQualification(false);
      })
      .catch((e) => {
        alert(e.response.data.message);
      });
  };

  const deleteQualification = async (board_id, qualification) => {
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}qualification/delete-qualification`,
        {
          id: board_id,
          qualification,
        }
      )
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {});
  };

  const fetchQualification = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}qualification//get-all-Qualifications`
      )
      .then((res) => {
        setQualification(res.data.Qualifications);
      })
      .catch((e) => {
        setQualification([]);
      });
  };

  useEffect(() => {
    fetchQualification();
  }, [callApi]);

  const qualification_data = {
    columns: [
      {
        label: "S.no",
        field: "s_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Qualification Name",
        field: "qualification_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: Qualification?.map((Qualification, index) => {
      return {
        s_no: index + 1,
        qualification_name: Qualification?.name,
        actions: (
          <td
            style={{ display: "flex", justifyContent: "start", width: "100%" }}
          >
            <button
              type="button"
              onClick={() => {
                setOpenEditQualification(true);
                setEditedBoardQualification(Qualification?.name);
                setSelectedQualification({
                  name: Qualification?.name,
                  id: Qualification?._id,
                });
              }}
              style={{
                width: "2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className="btn btn-success"
            >
              <i className="fas fa-edit"></i>
            </button>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setSelectedQualification({ id: Qualification._id });
                setSelectedQualificationActive(Qualification.active);
                setWarning({
                  text: `Do you want to change the status to ${
                    Qualification.active === 0 ? `Active?` : `Inactive?`
                  }`,
                  type: "status",
                });
              }}
              style={{
                width: "4rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className={
                Qualification.active === 0
                  ? `btn btn-danger`
                  : `btn btn-success`
              }
            >
              {Qualification.active === 0 ? `Inactive` : `Active`}
            </button>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setWarning({
                  text: `Do you want to delete the Qualification?`,
                  type: "delete",
                });
                setSelectedQualification({
                  name: Qualification?.name,
                  id: Qualification._id,
                });
              }}
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="btn btn-danger"
            >
              <i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      };
    }),
  };

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Breadcrumb title={"Qualification List"}></Breadcrumb>
            <Link to="/add-qualification">
              <Button>ADD Qualification</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={12}>
                <Box className="mc-card">
                  <CardHeader title="Qualification List" />
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={qualification_data}
                          style={{ marginTop: "1rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>
        </Col>

        {/* Edit Qualification Modal */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openEditQualification}
          onClose={() => setOpenEditQualification(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "30%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={5}
            >
              Edit Qualification
            </Typography>

            <Stack spacing={2} sx={{ alignItems: "center" }}>
              <TextField
                id="outlined-required"
                defaultValue=""
                placeholder="Enter Qualification ..."
                multiline
                label="Qualification"
                value={editedQualification}
                onChange={(e) => setEditedBoardQualification(e.target.value)}
                sx={{ width: "100%" }}
              />
            </Stack>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <button
                disabled={editedQualification ? false : true}
                onClick={editQualification}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </Sheet>
        </Modal>

        {/* Warning Qualification Modal */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openWarning}
          onClose={() => setOpenWarning(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "30%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={5}
            >
              {warning.text}
            </Typography>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2rem",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (warning.type === "status") {
                    changeStatus(
                      selectedQualification.id,
                      selectedQualificationActive
                    );
                    setOpenWarning(false);
                  } else if (warning.type === "delete") {
                    deleteQualification(
                      selectedQualification.id,
                      selectedQualification?.name
                    );
                    setOpenWarning(false);
                  }
                }}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-success"
              >
                Yes
              </button>
              <button
                onClick={() => setOpenWarning(false)}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-danger"
              >
                No
              </button>
            </div>
          </Sheet>
        </Modal>
      </Row>
    </PageLayout>
  );
}
