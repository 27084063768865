import React, { useState } from "react";
import { Box, Form, Heading, Button, Image } from "../../components/elements";
import Logo from "../../components/Logo";
import data from "../../data/master/forgot.json";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ResetPassword() {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");

  const Success = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const Error = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const resetPass = async () => {
    await Axios.post(`${process.env.REACT_APP_BASE_URL}admin/change-password`, {
      password,
      cpassword,
    })
      .then((res) => {
        Success(res.data.message);
        navigate("/login");
      })
      .catch((e) => {
        Error(e.response.data.message);
      });
  };

  return (
    <>
      <ToastContainer autoClose={1000} />
      <Box className="mc-auth">
        <Image
          src={data?.pattern.src}
          alt={data?.pattern.alt}
          className="mc-auth-pattern"
        />
        <Box className="mc-auth-group">
          <Logo
            src={data?.logo.src}
            alt={data?.logo.alt}
            href={data?.logo.path}
            className="mc-auth-logo"
          />
          <Heading as="h4" className="mc-auth-title">
            {data?.title}
          </Heading>
          <Form className="mc-auth-form">
            <TextField
              id="filled-basic"
              label="New Password"
              type="password"
              variant="filled"
              sx={{ width: "100%", mb: "1.5rem" }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              id="filled-basic"
              label="Confirm New Password"
              type="password"
              variant="filled"
              sx={{ width: "100%", mb: "1.5rem" }}
              value={cpassword}
              onChange={(e) => setCpassword(e.target.value)}
            />

            <Button
              onClick={resetPass}
              className={`mc-auth-btn ${data?.button.fieldSize}`}
              type={data?.button.type}
            >
              Reset Password
            </Button>
            {/* <Anchor className="mc-auth-forgot" href={ data?.forgot.path }>{ data?.forgot.text }</Anchor> */}
          </Form>
        </Box>
      </Box>
    </>
  );
}
