import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

export default function AddNotifications() {
  const navigate = useNavigate();

  const [notification, setNotification] = useState({
    name: "",
    date: null,
    time: null,
    description: "",
  });

  const handleChangeDate = (newValue) => {
    if (newValue.isValid()) {
      const isoFormattedDate = newValue.toISOString(); // Convert the time to ISO 8000 format
      setNotification({ ...notification, date: isoFormattedDate });
    }
  };

  const handleChangeTime = (newValue) => {
    if (newValue.isValid()) {
      const isoFormattedTime = newValue.toISOString(); // Convert the time to ISO 8000 format
      setNotification({ ...notification, time: isoFormattedTime });
    }
  };
  const currentDate = dayjs();

  const addNotification = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}notification/add-notification`,
        notification
      )
      .then((res) => {
        navigate("/notifications");
      })
      .catch((e) => {
        Error(e.response.data.message);
      });
  };

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Breadcrumb title={"Add Notifications"}></Breadcrumb>
            <Link to="/notifications">
              <Button className="btn btn-primary">Notifications List</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={2}>
                <CardHeader title="Title" />
                <TextField
                  id="outlined-required"
                  defaultValue=""
                  value={notification.name}
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setNotification({ ...notification, name: e.target.value })
                  }
                />
              </Col>

              <Col xl={2}>
                <CardHeader title="Date" />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat="MM/DD/YYYY"
                    value={notification.date}
                    onChange={handleChangeDate}
                    renderInput={(params) => <TextField {...params} />}
                    minDate={currentDate}
                  />
                </LocalizationProvider>
              </Col>

              <Col xl={2}>
                <CardHeader title="Time" />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    value={notification.time}
                    onChange={handleChangeTime}
                    placeholder="HH:MM"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Col>

              <Col xl={3}>
                <CardHeader title="Description" />
                <TextField
                  id="outlined-textarea"
                  placeholder="Enter Notification Description..."
                  multiline
                  value={notification.description}
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setNotification({
                      ...notification,
                      description: e.target.value,
                    })
                  }
                />
              </Col>

              <Col xl={2}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    alignItems: "center",
                    marginTop: "3.25rem",
                  }}
                >
                  <Link to="/sessions-list">
                    <Button
                      className="btn btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        addNotification();
                      }}
                    >
                      Add
                    </Button>
                  </Link>
                </div>
              </Col>

              <Col xl={12} />
            </Row>
          </Box>
        </Col>
      </Row>
    </PageLayout>
  );
}
