import React from "react";
import { Dropdown } from "react-bootstrap";
import { DuelText, RoundAvatar } from "..";
import { Anchor } from "../elements";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

export default function ProfileDropdown({ name, username, image, dropdown }) {
  const navigate = useNavigate();

  const Logout = async () => {
    await Axios.get("http://localhost:8000/admin/logout", {
      withCredentials: true,
    })
      .then((res) => {
        navigate("/login");
        localStorage.clear();
      })
      .catch((e) => {
      });
  };

  return (
    <Dropdown className="mc-header-user">
      <Dropdown.Toggle className="mc-dropdown-toggle">
        <RoundAvatar src={image} alt="avatar" size="xs" />
        <DuelText title={name} descrip={username} size="xs" />
      </Dropdown.Toggle>
      <Dropdown.Menu align="end" className="mc-dropdown-paper">
        {dropdown.map((item, index) => (
          <Anchor
            key={index}
            icon={item.icon}
            href={item.path}
            text={item.text}
            className="mc-dropdown-menu"
            onClick={item.text === "logout" ? Logout : null}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
