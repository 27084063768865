import React, { useState } from "react";
import {
  Box,
  Form,
  Heading,
  Button,
  Anchor,
  Image,
  Text,
} from "../../components/elements";
import IconField from "../../components/fields/IconField";
import Logo from "../../components/Logo";
import data from "../../data/master/login.json";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const Success = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const Error = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const Login = async () => {
    await Axios.post(`${process.env.REACT_APP_BASE_URL}admin/login`, {
      email,
      password,
    })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("admin", JSON.stringify(res.data.admin));
        navigate("/");
      })
      .catch((e) => {
        Error(e.response.data.message);
      });
  };

  return (
    <Box className="mc-auth">
      <ToastContainer autoClose={1000} />
      <Image
        src={data?.pattern.src}
        alt={data?.pattern.alt}
        className="mc-auth-pattern"
      />
      <Box className="mc-auth-group">
        <Logo
          src={data?.logo.src}
          alt={data?.logo.alt}
          href={data?.logo.path}
          className="mc-auth-logo"
        />
        <Heading as="h4" className="mc-auth-title">
          {data?.title}
        </Heading>
        <Form className="mc-auth-form">
          <TextField
            id="filled-basic"
            label="Email"
            type="text"
            variant="filled"
            sx={{ width: "100%", mb: "1.5rem" }}
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextField
            id="filled-basic"
            label="Password"
            type="password"
            variant="filled"
            sx={{ width: "100%", mb: "1.5rem" }}
            onChange={(e) => setPassword(e.target.value)}
          />
          {/* <FormControl sx={{width:280,height:60}}>
                     <Select
                     labelId="demo-simple-select-label"
                     id="demo-simple-select"
                     sx={{background : "#fff",height:40}}
                     defaultValue={10}
                     >
                     <MenuItem value={10}>Select Login Type</MenuItem>
                     <MenuItem value={20}>Admin</MenuItem>
                     <MenuItem value={30}>School</MenuItem>
                     </Select>
                     </FormControl> */}
          <Button
            onClick={Login}
            className={`mc-auth-btn ${data?.button.fieldSize}`}
            type={data?.button.type}
          >
            {data?.button.text}
          </Button>
          <Anchor className="mc-auth-forgot" href={data?.forgot.path}>
            {data?.forgot.text}
          </Anchor>
        </Form>
      </Box>
    </Box>
  );
}
