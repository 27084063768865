import React, { useState } from "react";
import { Box, Form, Heading, Button, Image } from "../../components/elements";
import IconField from "../../components/fields/IconField";
import Logo from "../../components/Logo";
import data from "../../data/master/school-login.json";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

export default function SchoolLogin() {
  const navigate = useNavigate();

  const [loginCreds, SetLoginCreds] = useState({
    email: "",
    password: "",
  });

  let name, value;

  const inputsHandler = (e) => {
    name = e.target.name;
    value = e.target.value;

    SetLoginCreds({ ...loginCreds, [name]: value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    localStorage.setItem("token", `asdasdasd22442afssffs`);
    navigate("/school-dashboard");

    await Axios.post("http://localhost:8000/admin/login", loginCreds, {
      withCredentials: true,
    })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("admin", JSON.stringify(res.data.admin));
        navigate("/school-dashboard");
      })
      .catch((e) => {});
  };

  return (
    <Box className="mc-auth">
      <Image
        src={data?.pattern.src}
        alt={data?.pattern.alt}
        className="mc-auth-pattern"
      />
      <Box className="mc-auth-group">
        <Logo
          src={data?.logo.src}
          alt={data?.logo.alt}
          href={data?.logo.path}
          className="mc-auth-logo"
        />
        <Heading as="h4" className="mc-auth-title">
          {data?.title}
        </Heading>
        <Form className="mc-auth-form">
          {data?.input.map((item, index) => (
            <IconField
              key={index}
              icon={item.icon}
              type={item.type}
              option={item.option}
              classes={item.fieldSize}
              placeholder={item.placeholder}
              name={item.name}
              value={
                item.name === "email" ? loginCreds.email : loginCreds.password
              }
              passwordVisible={item.passwordVisible}
              onChange={inputsHandler}
            />
          ))}
          {/* <FormControl sx={{width:280,height:60}}>
                     <Select
                     labelId="demo-simple-select-label"
                     id="demo-simple-select"
                     sx={{background : "#fff",height:40}}
                     defaultValue={10}
                     >
                     <MenuItem value={10}>Select Login Type</MenuItem>
                     <MenuItem value={20}>Admin</MenuItem>
                     <MenuItem value={30}>School</MenuItem>
                     </Select>
                     </FormControl> */}
          <Button
            onClick={submitHandler}
            className={`mc-auth-btn ${data?.button.fieldSize}`}
            type={data?.button.type}
          >
            {data?.button.text}
          </Button>
        </Form>
      </Box>
    </Box>
  );
}
