import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import TextField from "@mui/material/TextField";

import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Modal, ModalClose, Sheet, Stack, Typography } from "@mui/joy";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import moment from "moment";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
};

export default function GroupCounselling() {
  const [openViewStudent, setOpenViewStudent] = useState(false);

  const [monthDojs, setmonthDojs] = useState([]);

  const [monthDoes, setmonthDoes] = useState([]);

  const [yearDojs, setyearDojs] = useState([]);

  const [yearDoes, setyearDoes] = useState([]);

  const [aspiration, setAspiration] = useState("");

  const [grades, setGrades] = useState("");

  const [grade, setGrade] = useState("");

  const handleJoiningMonthChange = (event) => {
    const {
      target: { value },
    } = event;
    setmonthDojs(typeof value === "string" ? value.split(",") : value);
  };

  const handleExipireMonthChange = (event) => {
    const {
      target: { value },
    } = event;
    setmonthDoes(typeof value === "string" ? value.split(",") : value);
  };

  const handleJoiningYearChange = (event) => {
    const {
      target: { value },
    } = event;
    setyearDojs(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleExipireYearChange = (event) => {
    const {
      target: { value },
    } = event;
    setyearDoes(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const months_data = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];

  const years_data = ["2021", "2022", "2023", "2024"];

  const [allCounsellings, setAllCounsellings] = useState([]);

  const [aspirations, setAspirations] = useState([]);

  const getAllCounsellings = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}package/get-group-counselling-students`
      )
      .then((res) => {
        setAllCounsellings(res.data.studentDetails);
      })
      .catch((e) => {});
  };

  const fetchProfessions = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}profession/get-all-professions`)
      .then((res) => {
        setAspirations(res.data.Professions);
      })
      .catch((e) => {
        setAspiration([]);
      });
  };

  const student_data = {
    columns: [
      {
        label: "Batch Name",
        field: "batch_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "First Name",
        field: "first_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Last Name",
        field: "last_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Phone",
        field: "phone",
        sort: "asc",
        width: 200,
      },
      {
        label: "Board",
        field: "board",
        sort: "asc",
        width: 100,
      },
      {
        label: "School",
        field: "school",
        sort: "asc",
        width: 150,
      },
      {
        label: "Aspirant",
        field: "aspirant",
        sort: "asc",
        width: 100,
      },
      {
        label: "State",
        field: "state",
        sort: "asc",
        width: 100,
      },
      {
        label: "District",
        field: "district",
        sort: "asc",
        width: 100,
      },
      {
        label: "Grade",
        field: "grade",
        sort: "asc",
        width: 100,
      },
      {
        label: "Counselling Type",
        field: "counselling_type",
        sort: "asc",
        width: 100,
      },
      {
        label: "DOJ",
        field: "doj",
        sort: "asc",
        width: 100,
      },
      {
        label: "DOE",
        field: "doe",
        sort: "asc",
        width: 100,
      },
      {
        label: "Add Session",
        field: "add_session",
        sort: "asc",
        width: 100,
      },
      {
        label: "View Session",
        field: "view_session",
        sort: "asc",
        width: 100,
      },
    ],
    rows: allCounsellings?.map((counseling) => {
      return {
        batch_name: counseling.batchName,
        first_name: counseling.student.first_name,
        last_name: counseling.student.last_name,
        board: counseling.student.board,
        school: counseling.student.school,
        phone: counseling.student.mobile,
        aspirant: counseling.student.aspirant,
        state: counseling.student.state,
        district: counseling.student.district,
        grade: counseling.student.grade,
        counselling_type: "Group Counselling",
        doj: moment(counseling.student.doj).format("DD-MM-YYYY"),
        doe: moment(counseling.student.doe).format("DD-MM-YYYY"),
        add_session: (
          <Link
            to="/add-session"
            state={{ type: "group", batchId: counseling.batchId }}
          >
            <button
              type="button"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0.5rem 1rem",
                margin: "0.5rem",
              }}
              className="btn btn-primary"
            >
              Assign
            </button>
          </Link>
        ),
        view_session: (
          <Link to={`/sessions-list?batchId=${counseling.batchId}&type=group`}>
            <button
              type="button"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0.5rem 1rem",
                margin: "0.5rem",
              }}
              className="btn btn-primary"
            >
              View
            </button>
          </Link>
        ),
      };
    }),
  };

  const fetchGrades = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}grade/get-all-grades`)
      .then((res) => {
        setGrades(res.data.Grades);
      })
      .catch((e) => {
        setGrades([]);
      });
  };

  const getCounsellings = async () => {
    const requestBody = {};

    if (monthDojs.length > 0) {
      requestBody.monthDojs = monthDojs;
    }

    if (yearDojs.length > 0) {
      requestBody.yearDojs = yearDojs;
    }

    if (monthDoes.length > 0) {
      requestBody.monthDoes = monthDoes;
    }

    if (yearDoes.length > 0) {
      requestBody.yearDoes = yearDoes;
    }

    if (aspiration) {
      requestBody.aspirant = aspiration;
    }

    if (grade) {
      requestBody.grade = grade;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}package/filter-group-counsellings`,
        requestBody
      );
      setAllCounsellings(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getAllCounsellings();
    fetchProfessions();
  }, []);

  useEffect(() => {
    getCounsellings();
  }, []);

  useEffect(() => {
    fetchGrades();
  }, []);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <Breadcrumb title={"Group Counselling Students List"}></Breadcrumb>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Month DOJ" />
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={monthDojs}
                      onChange={handleJoiningMonthChange}
                      input={<OutlinedInput />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {months_data.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={monthDojs.includes(name)} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Year DOJ" />
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={yearDojs}
                      onChange={handleJoiningYearChange}
                      input={<OutlinedInput />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {years_data.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={yearDojs.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Month DOE" />
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={monthDoes}
                      onChange={handleExipireMonthChange}
                      input={<OutlinedInput />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {months_data.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={monthDoes.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Year DOE" />
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={yearDoes}
                      onChange={handleExipireYearChange}
                      input={<OutlinedInput />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {years_data.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={yearDoes.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Select Aspiration" />
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={aspiration}
                      onChange={(e) => setAspiration(e.target.value)}
                    >
                      <MenuItem value="">None</MenuItem>
                      {aspirations.length > 0 &&
                        aspirations.map((aspiration) => {
                          return (
                            <MenuItem value={aspiration.name}>
                              {aspiration.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Select Grade" />
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={grade}
                      onChange={(e) => setGrade(e.target.value)}
                    >
                      <MenuItem value="">None</MenuItem>
                      {grades.length > 0 &&
                        grades.map((grade) => {
                          return (
                            <MenuItem key={grade._id} value={grade._id}>
                              {grade.board.name}-{grade.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={10}></Col>
              <Col xl={2}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      getCounsellings();
                    }}
                    className="btn btn-primary"
                  >
                    Apply
                  </Button>
                </div>
              </Col>

              <Col xl={12}>
                <Box className="mc-card">
                  <div className="container" style={{ marginTop: "1rem" }}>
                    <div
                      className="row"
                      style={{ justifyContent: "end", width: "100%" }}
                    ></div>
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={student_data}
                          style={{
                            marginTop: "1rem",
                            display: "block",
                            overflowX: "auto",
                            whiteSpace: "nowrap",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>
        </Col>

        {/* View Student Modal */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openViewStudent}
          onClose={() => setOpenViewStudent(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "50%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={1}
            >
              Add Feedback
            </Typography>

            <Stack spacing={2} sx={{ alignItems: "center" }}>
              <CardHeader title="Feedback" />
              <TextField
                id="outlined-required"
                defaultValue=""
                placeholder="Enter your feedback ..."
                multiline
                sx={{ width: "100%" }}
              />
            </Stack>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <button
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </Sheet>
        </Modal>
      </Row>
    </PageLayout>
  );
}
