import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import PageLayout from "../../layouts/PageLayout";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { Modal, ModalClose, Sheet, Typography } from "@mui/joy";

export default function SubjectsList() {
  const [subjects, setSubjects] = useState([]);
  const [callApi, setCallApi] = useState(false);
  const [board, setBoard] = useState([]);
  const [grade, setGrade] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [filters, setFilters] = useState({
    board: "",
    grade: "",
  });
  const [warning, setWarning] = useState({
    text: "",
    status: "",
  });

  const [openWarning, setOpenWarning] = useState(false);
  const [selectedSubjectActive, setSelectedSubjectActive] = useState(0);
  const fetchGrades = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}grade/get-all-grades`;
    const queryParameters = Object.entries(filters)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    if (queryParameters) {
      url += `?${queryParameters}`;
    }
    await axios
      .get(url)
      .then((res) => {
        setGrade(res.data.Grades);
      })
      .catch((e) => {
        setGrade([]);
      });
  };

  const fetchBoards = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}board/get-all-boards`;
    const queryParameters = Object.entries(filters)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    if (queryParameters) {
      url += `?${queryParameters}`;
    }
    await axios
      .get(url)
      .then((res) => {
        setBoard(res.data.Boards);
      })
      .catch((e) => {
        setBoard([]);
      });
  };
  const fetchSubjects = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}subject/get-all-subjects`)
      .then((res) => {
        setSubjects(res.data.subjects);
      })
      .catch((e) => {
        setSubjects([]);
      });
  };

  const changeStatus = async (subject_id, active) => {
    let FinalStatus;
    if (active === 0) {
      FinalStatus = 1;
    } else {
      FinalStatus = 0;
    }
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}subject/change-status`, {
        id: subject_id,
        active: FinalStatus,
      })
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {});
  };

  const deleteSubject = async (subject_id) => {
    await axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}subject/delete-subject/${subject_id}`
      )
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {});
  };

  const subject_data = {
    columns: [
      {
        label: "S.no",
        field: "s_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Subject Name",
        field: "subject_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Board Name",
        field: "board_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Grade Name",
        field: "grade_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: subjects?.map((subject, index) => {
      return {
        s_no: index + 1,
        board_name: subject.board.name,
        subject_name: subject.name,
        grade_name: subject.grade.name,
        actions: (
          <td
            style={{ display: "flex", justifyContent: "start", width: "100%" }}
          >
            <Link to="/edit-subject" state={{ Subject: subject }}>
              <button
                type="button"
                style={{
                  width: "2rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "1.6rem",
                }}
                className="btn btn-success"
              >
                .<i className="fas fa-edit"></i>
              </button>
            </Link>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setSelectedSubject({ id: subject._id });
                setSelectedSubjectActive(subject.active);
                setWarning({
                  text: `Do you want to change the status to ${
                    subject.active === 0 ? `Active?` : `Inactive?`
                  }`,
                  type: "status",
                });
              }}
              style={{
                width: "4rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className={
                subject.active === 0 ? `btn btn-danger` : `btn btn-success`
              }
            >
              {subject.active === 0 ? `Inactive` : `Active`}
            </button>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setWarning({
                  text: `Do you want to delete the subject?`,
                  type: "delete",
                });
                setSelectedSubject({ name: subject.name, id: subject._id });
              }}
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="btn btn-danger"
            >
              .<i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      };
    }),
  };

  const fetchFilterSubjects = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}subject/get-all-subjects`;
    const queryParameters = Object.entries(filters)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    if (queryParameters) {
      url += `?${queryParameters}`;
    }
    await axios
      .get(url)
      .then((res) => {
        setSubjects(res.data.subjects);
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchFilterSubjects();
    fetchBoards();
    fetchGrades();
  }, [callApi]);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Breadcrumb title={"Subjects List"}></Breadcrumb>
            <Link to="/add-subject">
              <Button className="btn btn-primary">Add Subject</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={3}>
                <CardHeader title="Board" />
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">Board</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Board"
                    value={filters.board}
                    onChange={(e) => {
                      setFilters({ ...filters, board: e.target.value });
                      setCallApi(!callApi);
                    }}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {board &&
                      board.map((item, index) => (
                        <MenuItem key={index} value={item?._id}>
                          {item?.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Col>
              <Col xl={3}>
                <CardHeader title="Grade" />
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">Grade</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Grade"
                    value={filters.grade}
                    onChange={(e) => {
                      setFilters({ ...filters, grade: e.target.value });
                      setCallApi(!callApi);
                    }}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {grade &&
                      grade.map((item, index) => (
                        <MenuItem key={index} value={item._id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Col>

              <Col xl={1}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    alignItems: "center",
                    marginTop: "3.25rem",
                  }}
                >
                  <Button
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      fetchFilterSubjects();
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </Col>
              <Col xl={12}>
                <Box className="mc-card">
                  <CardHeader title="Subjects List" />
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={subject_data}
                          style={{ marginTop: "1rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>
        </Col>

        {/* Warning Board Modal */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openWarning}
          onClose={() => setOpenWarning(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "30%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={5}
            >
              {warning.text}
            </Typography>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2rem",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (warning.type === "status") {
                    changeStatus(selectedSubject.id, selectedSubjectActive);
                    setOpenWarning(false);
                  } else if (warning.type === "delete") {
                    deleteSubject(selectedSubject.id, selectedSubject.name);
                    setOpenWarning(false);
                  }
                }}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-success"
              >
                Yes
              </button>
              <button
                onClick={() => setOpenWarning(false)}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-danger"
              >
                No
              </button>
            </div>
          </Sheet>
        </Modal>
      </Row>
    </PageLayout>
  );
}
