import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import TextField from "@mui/material/TextField";

import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import moment from "moment";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
};

export default function EditBatch() {
  const [grades, setGrades] = useState([]);

  const [aspirations, setAspirations] = useState([]);

  const [monthDojs, setmonthDojs] = useState([]);

  const [monthDoes, setmonthDoes] = useState([]);

  const [yearDojs, setyearDojs] = useState([]);

  const [yearDoes, setyearDoes] = useState([]);

  const [aspiration, setAspiration] = useState("");

  const [grade, setGrade] = useState("");

  const navigate = useNavigate();

  const [setLoading] = useState(false);

  const location = useLocation();

  const Batch = location.state.Batch;

  const [students, setStudents] = useState([]);

  const [openAssignStudents, setOpenAssignStudents] = useState(false);

  const [selectedStudents, setSelectedStudents] = useState(Batch.students);

  const [boards, setBoards] = useState(Batch.boards.map((board) => board._id));

  const handleCheckboxChange = (event, student) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add the question to the selectedQuestions array
      setSelectedStudents((prevSelectedStudent) => [
        ...prevSelectedStudent,
        student._id,
      ]);
    } else {
      // Remove the question from the selectedStudents array
      setSelectedStudents((prevSelectedStudents) =>
        prevSelectedStudents.filter(
          (selectedStudent) => selectedStudent !== student._id
        )
      );
    }
  };

  const [batch, setBatch] = useState({
    number: Batch.number,
    aspirant: Batch.aspirant,
    year: Batch.year,
    quarter: Batch.quarter,
    boards: Batch.boards.map((board) => board._id),
    students: Batch.students,
  });

  const months_data = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];

  const years_data = ["2021", "2022", "2023", "2024"];

  const fetchBoards = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}board/get-all-boards`)
      .then((res) => {
        setBoards(res.data.Boards);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((e) => {
        setBoards([]);
      });
  };

  const fetchStudentsList = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}package/get-group-students`)
      .then((res) => {
        setStudents(res.data.students);
        const uniqueAspirations = [
          ...new Set(res.data.students.flatMap((item) => item.user.aspirant)),
        ];
        setAspirations(uniqueAspirations);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((e) => {
        //   alert("Session Expired !");
        Error(e.response.data.message);
        localStorage.clear();
      });
  };

  const handleBoardChange = (value) => {
    if (value.includes("all")) {
      // Selecting all boards
      setBatch({ ...batch, boards: boards.map((board) => board._id) });
    } else {
      // Selecting a specific board
      if (value.length > 0) {
        setBatch({ ...batch, boards: [value[value.length - 1]] });
      } else {
        setBatch({ ...batch, boards: [] });
      }
    }
  };

  const handleJoiningMonthChange = (event) => {
    const {
      target: { value },
    } = event;
    setmonthDojs(typeof value === "string" ? value.split(",") : value);
  };

  const handleExipireMonthChange = (event) => {
    const {
      target: { value },
    } = event;
    setmonthDoes(typeof value === "string" ? value.split(",") : value);
  };

  const handleJoiningYearChange = (event) => {
    const {
      target: { value },
    } = event;
    setyearDojs(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleExipireYearChange = (event) => {
    const {
      target: { value },
    } = event;
    setyearDoes(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const student_data = {
    columns: [
      {
        label: "S.no",
        field: "s_no",
        sort: "asc",
        width: 150,
      },
      {
        label: "First Name",
        field: "first_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Last Name",
        field: "last_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Phone",
        field: "phone",
        sort: "asc",
        width: 200,
      },
      {
        label: "Board",
        field: "board",
        sort: "asc",
        width: 100,
      },
      {
        label: "School",
        field: "school",
        sort: "asc",
        width: 150,
      },
      {
        label: "Aspirant",
        field: "aspirant",
        sort: "asc",
        width: 100,
      },
      {
        label: "State",
        field: "state",
        sort: "asc",
        width: 100,
      },
      {
        label: "District",
        field: "district",
        sort: "asc",
        width: 100,
      },
      {
        label: "Grade",
        field: "grade",
        sort: "asc",
        width: 100,
      },
      {
        label: "DOJ",
        field: "doj",
        sort: "asc",
        width: 100,
      },
      {
        label: "DOE",
        field: "doe",
        sort: "asc",
        width: 100,
      },
    ],
    rows: students?.map((student, index) => {
      return {
        s_no: (
          <th scope="row">
            <input
              type="checkbox"
              checked={selectedStudents.some(
                (selectedStudent) => selectedStudent === student._id
              )}
              onChange={(event) => handleCheckboxChange(event, student)}
            />
            &nbsp;{index + 1}
          </th>
        ),
        first_name: student.user.first_name,
        last_name: student.user.last_name,
        board: student.user.board,
        school: student.user.school,
        phone: student.user.mobile,
        aspirant: student.user.aspirant,
        state: student.user.state,
        district: student.user.district,
        grade: student.user.grade,
        doj: moment(student.user.doj).format("DD-MM-YYYY"),
        doe: moment(student.user.doe).format("DD-MM-YYYY"),
      };
    }),
  };

  const fetchGrades = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}grade/get-all-grades`)
      .then((res) => {
        setGrades(res.data.Grades);
      })
      .catch((e) => {
        setGrades([]);
      });
  };

  const getCounsellings = async () => {
    const requestBody = {};

    if (monthDojs.length > 0) {
      requestBody.monthDojs = monthDojs;
    }

    if (yearDojs.length > 0) {
      requestBody.yearDojs = yearDojs;
    }

    if (monthDoes.length > 0) {
      requestBody.monthDoes = monthDoes;
    }

    if (yearDoes.length > 0) {
      requestBody.yearDoes = yearDoes;
    }

    if (aspiration) {
      requestBody.aspirant = aspiration;
    }

    if (grade) {
      requestBody.grade = grade;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}package/filter-students`,
        requestBody
      );

      setStudents(response.data);
      const uniqueAspirations = [
        ...new Set(
          response.data.students.flatMap((item) => item.user.aspirant)
        ),
      ];
      setAspirations(uniqueAspirations);
    } catch (error) {}
  };

  const editBatch = async () => {
    await axios
      .put(`${process.env.REACT_APP_BASE_URL}batch/edit-batch/${Batch._id}`, {
        aspirant: batch.aspirant,
        year: batch.year,
        number: batch.number,
        quarter: batch.quarter,
        boards: batch.boards,
        students: selectedStudents,
      })
      .then((res) => {
        navigate("/batches-list");
      })
      .catch((e) => {
        Error(e.response.data.message);
      });
  };

  useEffect(() => {
    getCounsellings();
  }, []);

  useEffect(() => {
    fetchGrades();
  }, []);

  useEffect(() => {
    fetchBoards();
    fetchStudentsList();
  }, []);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Breadcrumb title={"Add Batch"}></Breadcrumb>
            <Link to="/batches-list">
              <Button className="btn btn-primary">View Batches</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={2}>
                <CardHeader title="Aspirant" />
                <TextField
                  id="outlined-required"
                  defaultValue=""
                  sx={{ width: "100%" }}
                  value={batch.aspirant}
                  onChange={(e) =>
                    setBatch({ ...batch, aspirant: e.target.value })
                  }
                />
              </Col>

              <Col xl={2}>
                <CardHeader title="Year" />
                <FormControl sx={{ width: 150 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={batch.year}
                    onChange={(e) =>
                      setBatch({ ...batch, year: e.target.value })
                    }
                  >
                    <MenuItem value={"2021"}>2021</MenuItem>
                    <MenuItem value={"2022"}>2022</MenuItem>
                    <MenuItem value={"2023"}>2023</MenuItem>
                    <MenuItem value={"2024"}>2024</MenuItem>
                    <MenuItem value={"2025"}>2025</MenuItem>
                  </Select>
                </FormControl>
              </Col>

              <Col xl={2}>
                <CardHeader title="Batch No." />
                <TextField
                  id="outlined-required"
                  defaultValue=""
                  value={batch.number}
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setBatch({ ...batch, number: e.target.value })
                  }
                />
              </Col>

              <Col xl={2}>
                <CardHeader title="Board" />
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">Board</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Board"
                    value={batch.boards}
                    onChange={(e) => handleBoardChange(e.target.value)}
                    multiple
                  >
                    <MenuItem value="all">ALL</MenuItem>{" "}
                    {/* Add default option for selecting all boards */}
                    {boards.length > 0 &&
                      boards.map((board) => {
                        return (
                          <MenuItem key={board._id} value={board._id}>
                            {board.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Col>

              <Col xl={2}>
                <div
                  style={{
                    marginTop: "2.5rem",
                    justifyContent: "start",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <Button
                    onClick={() => setOpenAssignStudents(true)}
                    className="btn btn-primary"
                  >
                    Assign Students
                  </Button>
                </div>
              </Col>

              <Col xl={2}>
                <CardHeader title="Students Assigned" />
                <TextField
                  id="outlined-required"
                  defaultValue="0"
                  placeholder="0"
                  disabled
                  value={selectedStudents.length}
                  sx={{ width: "100%" }}
                />
              </Col>

              <Col xl={3}>
                <CardHeader title="Quarter" />
                <FormControl sx={{ width: 260 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={batch.quarter}
                    onChange={(e) => {
                      setBatch({ ...batch, quarter: e.target.value });
                    }}
                  >
                    <MenuItem value="april">April(Jan, Feb, Mar, Apr)</MenuItem>
                    <MenuItem value="august">Aug(May, Jun, Jul, Aug)</MenuItem>
                    <MenuItem value="september">
                      September(Sep, Oct, Nov, Dec)
                    </MenuItem>
                  </Select>
                </FormControl>
              </Col>

              <Col xl={2}>
                <div
                  style={{
                    marginTop: "3.25rem",
                    justifyContent: "start",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <Button
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      editBatch();
                    }}
                  >
                    Edit
                  </Button>
                </div>
              </Col>

              <Col xl={12} />
            </Row>
          </Box>
        </Col>
      </Row>

      {/* Assign Student */}
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={openAssignStudents}
        onClose={() => setOpenAssignStudents(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "1000",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: "90%",
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
            bgcolor: "#fff",
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "#eee",
            }}
          />
          <Typography
            id="basic-modal-dialog-title"
            component="h2"
            level="inherit"
            fontSize="1.25em"
            mb={1}
          >
            Assign Students
          </Typography>

          <Row xs={1} md={1} xl={1} style={{ marginBottom: "2rem" }}>
            <Col xl={12}>
              <Box className="mc-card">
                <Row xs={1} md={1} xl={1}>
                  <Col xl={2}>
                    <div style={{ marginTop: "2rem" }}>
                      <CardHeader title="Month DOJ" />
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={monthDojs}
                          onChange={handleJoiningMonthChange}
                          input={<OutlinedInput />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {months_data.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox checked={monthDojs.includes(name)} />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Col>

                  <Col xl={2}>
                    <div style={{ marginTop: "2rem" }}>
                      <CardHeader title="Year DOJ" />
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={yearDojs}
                          onChange={handleJoiningYearChange}
                          input={<OutlinedInput />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {years_data.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox checked={yearDojs.indexOf(name) > -1} />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Col>

                  <Col xl={2}>
                    <div style={{ marginTop: "2rem" }}>
                      <CardHeader title="Month DOE" />
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={monthDoes}
                          onChange={handleExipireMonthChange}
                          input={<OutlinedInput />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {months_data.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={monthDoes.indexOf(name) > -1}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Col>

                  <Col xl={2}>
                    <div style={{ marginTop: "2rem" }}>
                      <CardHeader title="Year DOE" />
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={yearDoes}
                          onChange={handleExipireYearChange}
                          input={<OutlinedInput />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {years_data.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox checked={yearDoes.indexOf(name) > -1} />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Col>

                  <Col xl={2}>
                    <div style={{ marginTop: "2rem" }}>
                      <CardHeader title="Select Aspiration" />
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={aspiration}
                          onChange={(e) => setAspiration(e.target.value)}
                        >
                          <MenuItem value="">None</MenuItem>
                          {aspirations.length > 0 &&
                            aspirations.map((aspiration) => {
                              return (
                                <MenuItem value={aspiration}>
                                  {aspiration}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </div>
                  </Col>

                  <Col xl={2}>
                    <div style={{ marginTop: "2rem" }}>
                      <CardHeader title="Select Grade" />
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={grade}
                          onChange={(e) => setGrade(e.target.value)}
                        >
                          <MenuItem value="">None</MenuItem>
                          {grades.length > 0 &&
                            grades.map((grade) => {
                              return (
                                <MenuItem key={grade._id} value={grade._id}>
                                  {grade.board.name}-{grade.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </div>
                  </Col>

                  <Col xl={10}></Col>
                  <Col xl={2}>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          getCounsellings();
                        }}
                        className="btn btn-primary"
                      >
                        Apply
                      </Button>
                    </div>
                  </Col>

                  <Col xl={12}>
                    <Box className="mc-card">
                      <div className="container" style={{ marginTop: "1rem" }}>
                        <div
                          className="row"
                          style={{ justifyContent: "end", width: "100%" }}
                        ></div>
                        <div className="row">
                          <div className="col-12">
                            <MDBDataTable
                              striped
                              bordered
                              small
                              data={student_data}
                              style={{
                                marginTop: "1rem",
                                display: "block",
                                overflowX: "auto",
                                whiteSpace: "nowrap",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Col>
                </Row>
              </Box>
            </Col>
          </Row>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "2rem",
            }}
          >
            <button
              type="button"
              onClick={() => setOpenAssignStudents(false)}
              style={{
                width: "8rem",
                fontSize: "0.8rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "0.5rem",
              }}
              className="btn btn-primary"
            >
              Assign
            </button>
          </div>
        </Sheet>
      </Modal>
    </PageLayout>
  );
}
