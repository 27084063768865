import React from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";

import TextField from "@mui/material/TextField";

import { Link } from "react-router-dom";

function AddCounsellingType() {
  return (
    <>
      <PageLayout>
        <Row>
          <Col xl={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Breadcrumb title={"Add Counselling type"}></Breadcrumb>
              <Link to="/counselling-types-list">
                <Button className="btn btn-primary">
                  View Counselling Types List
                </Button>
              </Link>
            </div>
          </Col>
          <Col xl={12}>
            <Box className="mc-card">
              <Row xs={1} md={1} xl={1}>
                <Col xl={3}>
                  <div style={{ marginTop: "2rem" }}>
                    <CardHeader title="Counselling Type Name" />
                    <TextField
                      id="outlined-required"
                      defaultValue=""
                      placeholder="Enter Name of Counselling"
                      sx={{ width: "100%" }}
                    />
                  </div>
                </Col>

                <Col xl={2}>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "start",
                      alignItems: "center",
                      marginTop: "5.25rem",
                    }}
                  >
                    <Link to="/counselling-list">
                      <Button className="btn btn-primary">Add</Button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Box>
          </Col>
        </Row>
      </PageLayout>
    </>
  );
}

export default AddCounsellingType;
