import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb, DivideTitle } from "../../components";
import { CardHeader } from "../../components/cards";
import { LabelField,IconField,LabelTextarea, } from "../../components/fields";
import { Box, Item, Anchor, Text,Button } from "../../components/elements";
import SchoolPageLayout from "../../layouts/SchoolPageLayout";
import { Table, Thead, Tbody, Th, Tr, Td } from "../../components/elements/Table";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Modal, ModalClose, Sheet, Stack, Typography } from "@mui/joy";






export default function SchoolStudentsList() {

  const [ openViewStudent, setOpenViewStudent ] = useState(false);

    const [value, setValue] = React.useState(dayjs(new Date()));

    const handleChange = (newValue) => {
      setValue(newValue);
    };

    const student_data = {
      columns: [
        {
          label: 'First Name',
          field: 'first_name',
          sort: 'asc',
          width: 150
        },
        {
          label: 'Last Name',
          field: 'last_name',
          sort: 'asc',
          width: 150
        },
        {
          label: 'Phone',
          field: 'phone',
          sort: 'asc',
          width: 200
        },
        {
          label: 'Board',
          field: 'board',
          sort: 'asc',
          width: 100
        },
        {
          label: 'School',
          field: 'school',
          sort: 'asc',
          width: 150
        },
        {
          label: 'Aspirant',
          field: 'aspirant',
          sort: 'asc',
          width: 100
        },
        {
          label: 'State',
          field: 'state',
          sort: 'asc',
          width: 100
        },
        {
          label: 'District',
          field: 'district',
          sort: 'asc',
          width: 100
        },
        {
          label: 'Grade',
          field: 'grade',
          sort: 'asc',
          width: 100
        },
        {
          label: 'Actions',
          field: 'actions',
          sort: 'asc',
          width: 150
        }
      ],
      rows: [
        {
          first_name: 'Swapnil',
          last_name : 'Chatterjee',
          board: 'ICSE',
          school: 'Don Bosco',
          phone: '6290334123',
          aspirant : 'Engineer',
          state : 'Delhi',
          district : 'New Delhi',
          grade : '5',
          actions:
          <td style={{display:"flex",justifyContent:"space-evenly",width:"100%"}}>
            <button type="button" onClick={() => setOpenViewStudent(true)} style={{width:"2rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-primary"><i className="fas fa-eye"></i></button>
            <button type="button" style={{width:"2rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-success"><i className="fas fa-edit"></i></button>
            <button type="button" style={{width:"4rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-danger">Inactive</button>
            <button type="button" style={{width:"2rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center"}} className="btn btn-danger"><i className="fas fa-trash"></i></button>
          </td>
        },
        {
          first_name: 'Sunil',
          last_name : 'Chatterjee',
          board: 'CBSE',
          school: 'DPS',
          phone: '6290334123',
          aspirant : 'Engineer',
          state : 'Delhi',
          district : 'New Delhi',
          grade : '5',
          actions:
          <td style={{display:"flex",justifyContent:"space-evenly",width:"100%"}}>
            <button type="button" onClick={() => setOpenViewStudent(true)} style={{width:"2rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-primary"><i className="fas fa-eye"></i></button>
            <button type="button" style={{width:"2rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-success"><i className="fas fa-edit"></i></button>
            <button type="button" style={{width:"4rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-danger">Inactive</button>
            <button type="button" style={{width:"2rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center"}} className="btn btn-danger"><i className="fas fa-trash"></i></button>
          </td>
        }
      ]
    };
    
    return (
        <SchoolPageLayout>
            <Row>
                <Col xl={12}>
                    <Breadcrumb title={ "Students List" }>
                    </Breadcrumb>        
                </Col>
                <Col xl={12}>
                    <Box className="mc-card">
                        <Row xs={1} md={1} xl={1}>

                       

                        <Col xl={2}>

                        <div style={{marginTop:"2rem"}}>
                        <CardHeader title="Select Board" />
                        <FormControl sx={{width:150}}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                          >
                            <MenuItem value={10}>CBSE</MenuItem>
                            <MenuItem value={20}>ICSE</MenuItem>
                            <MenuItem value={30}>WB</MenuItem>
                            
                          </Select>
                            
                        </FormControl>
                        </div>
                            
                        </Col>
                            
                        <Col xl={2}>
                            
                        <div style={{marginTop:"2rem"}}>
                        <CardHeader title="Select Grade" />
                        <FormControl sx={{width:150}}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                          >
                            <MenuItem value={10}>Primary</MenuItem>
                            
                          </Select>
                            
                        </FormControl>
                        </div>
                            
                        </Col>
                            
                        <Col xl={2}>
                        <div style={{marginTop:"2rem"}}>
                        <CardHeader title="Select Aspiration" />
                        <FormControl sx={{width:150}}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                          >
                            <MenuItem value={10}>Engineer</MenuItem>
                            
                          </Select>
                            
                        </FormControl>
                        </div>
                        </Col>
                            
                        <Col xl={2}>
                        <div style={{marginTop:"2rem"}}>
                        <CardHeader title="Select State" />
                        <FormControl sx={{width:150}}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                          >
                            <MenuItem value={10}>Delhi</MenuItem>
                            
                          </Select>
                            
                        </FormControl>
                        </div>
                        </Col>

                        <Col xl={2}>
                        <div style={{marginTop:"2rem"}}>
                        <CardHeader title="Select District" />
                        <FormControl sx={{width:150}}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                          >
                            <MenuItem value={10}>Gurgaon</MenuItem>
                            
                          </Select>
                            
                        </FormControl>
                        </div>
                        </Col>
                            
                            
                        <Col xl={2}>
                        <div style={{display:"flex",width:"100%", justifyContent:"start", alignItems:"center" ,marginTop:"5.25rem"}}>
                        <Button className="btn btn-primary">Apply</Button>
                        </div>
                        </Col>
                                
                             <Col xl={12}>



                             <Box className="mc-card">
                             <CardHeader title="Students List" />
                             <div className="container">
                              <div className="row" style={{justifyContent:"end", width:"100%"}}>
                              <Col xl={3}>
                              <div style={{display:"flex",width:"100%"}}>
                              <Button className="btn btn-primary">Import Students</Button>
                              </div>
                              </Col>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                <MDBDataTable
                                  striped
                                  bordered
                                  small
                                  data={student_data}
                                  style={{marginTop:"1rem"}}
                                  />
                                
                                </div>
                              </div>
                            </div>
                                                        
                            </Box>
                            </Col>
                           
                        </Row>
                    </Box>
                </Col>

                  {/* View Student Modal */}
                <Modal
                      aria-labelledby="modal-title"
                      aria-describedby="modal-desc"
                      open={openViewStudent}
    
                      onClose={() => setOpenViewStudent(false)}
                      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex:"1000" }}
                    >
                      <Sheet
                        variant="outlined"
                        sx={{
                          width: '50%',
                          borderRadius: 'md',
                          p: 3,
                          boxShadow: 'lg',
                          bgcolor:"#fff"
                        }}
                      >
                        <ModalClose
                          variant="outlined"
                          sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: '#eee',
                          }}
                        />
                         <Typography
                          id="basic-modal-dialog-title"
                          component="h2"
                          level="inherit"
                          fontSize="1.25em"
                          mb={1}
                        >
                          View Student
                        </Typography>

                        <Stack spacing={2} sx={{alignItems:'center'}}>
                     <div style={{width:"5.5rem",height:"5.5rem",borderRadius:"50%",
                            backgroundImage:"url(https://images.hindustantimes.com/rf/image_size_800x600/HT/p2/2015/12/01/Pictures/_c34102da-9849-11e5-b4f4-1b7a09ed2cea.jpg)",display:"flex",justifyContent:"center",alignItems:"center",cursor:"pointer"}}>
                      </div>
                      <div style={{display:'flex',justifyContent:"start", width:"60%",padding:"2rem",border:'1px solid #eee',marginTop:"2rem"}}>
                        
                        <Col xl={6}>
                      <Typography
                          id="basic-modal-dialog-title"
                          component="h3"
                          level="inherit"
                          fontSize="1rem"
                          mb={2}
                        >  
                        First Name : 
                        </Typography>
                        <Typography
                          id="basic-modal-dialog-title"
                          component="h3"
                          level="inherit"
                          fontSize="1rem"
                          mb={2}
                        >  
                        Last Name : 
                        </Typography>
                        <Typography
                          id="basic-modal-dialog-title"
                          component="h3"
                          level="inherit"
                          fontSize="1rem"
                          mb={2}
                        >  
                        Phone : 
                        </Typography>

                        <Typography
                          id="basic-modal-dialog-title"
                          component="h3"
                          level="inherit"
                          fontSize="1rem"
                          mb={2}
                        >  
                        Board : 
                        </Typography>
                        <Typography
                          id="basic-modal-dialog-title"
                          component="h3"
                          level="inherit"
                          fontSize="1rem"
                          mb={2}
                        >  
                        School : 
                        </Typography>

                        <Typography
                          id="basic-modal-dialog-title"
                          component="h3"
                          level="inherit"
                          fontSize="1rem"
                          mb={2}
                        >  
                        Aspirant : 
                        </Typography>
                        <Typography
                          id="basic-modal-dialog-title"
                          component="h3"
                          level="inherit"
                          fontSize="1rem"
                          mb={2}
                        >  
                        Grade : 
                        </Typography>

                        </Col>

                        <Col xl={6}>

                        <Typography
                          id="basic-modal-dialog-title"
                          component="h6"
                          level="inherit"
                          fontSize="1rem"
                          mb={2}
                        >  
                        Swapnil
                        </Typography>

                        <Typography
                          id="basic-modal-dialog-title"
                          component="h6"
                          level="inherit"
                          fontSize="1rem"
                          mb={2}
                        >  
                        Chatterjee
                        </Typography>

                        <Typography
                          id="basic-modal-dialog-title"
                          component="h6"
                          level="inherit"
                          fontSize="1rem"
                          mb={2}
                        >  
                        6290345277
                        </Typography>

                        <Typography
                          id="basic-modal-dialog-title"
                          component="h6"
                          level="inherit"
                          fontSize="1rem"
                          mb={2}
                        >  
                        ICSE
                        </Typography>
                        <Typography
                          id="basic-modal-dialog-title"
                          component="h6"
                          level="inherit"
                          fontSize="1rem"
                          mb={2}
                        >  
                        Don Bosco
                        </Typography>
                        <Typography
                          id="basic-modal-dialog-title"
                          component="h6"
                          level="inherit"
                          fontSize="1rem"
                          mb={2}
                        >  
                        Engineer
                        </Typography>

                        <Typography
                          id="basic-modal-dialog-title"
                          component="h6"
                          level="inherit"
                          fontSize="1rem"
                          mb={2}
                        >  
                        5
                        </Typography>
                        
                        </Col>

                        
                      </div>
                  
                       </Stack>
                      
                       <div style={{width:"100%", display:"flex", justifyContent:"center",marginTop:"2rem"}}>
                      <button type="button" onClick={() => setOpenViewStudent(false)} style={{width:"8rem",fontSize:"0.8rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"0.5rem"}} className="btn btn-danger">Close</button>
                      </div>

                      </Sheet>
                    </Modal>
            </Row>
        </SchoolPageLayout>
    )
}