import React from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import SchoolPageLayout from "../../layouts/SchoolPageLayout";
import data from "../../data/master/ecommerce.json";
import { Box, Item, Anchor } from "../../components/elements";
import { EcommerceCard, SalesCard, ProductsCard, RevenueCard, ClientsCard, ActivityCard, OrdersCard, CardHeader } from "../../components/cards";
import { Navigate } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import TextField from '@mui/material/TextField';
import SchoolStudentsList from "./SchoolStudentsList";

export default function SchoolDashboard() {

    const token = localStorage.getItem("token");

    return (
        <>
        {token?
        <>
        <SchoolPageLayout>
            <Row>
                <Col xl={12}>
                    <Box className="mc-card">
                        <Breadcrumb title={data?.pageTitle}>
                            {data?.breadcrumb?.map((item, index) => (
                                <Item key={index} className="mc-breadcrumb-item">
                                    {item.path ? <Anchor className="mc-breadcrumb-link" href={item.path}>{item.text}</Anchor> : item.text}
                                </Item>
                            ))}
                        </Breadcrumb>
                    </Box>
                </Col>
      
                <Col xs={12} xl={4}>
                    <Row xs={1} lg={1} xl={1}>
         
                       <Col xl={6}>
                       <Box className="mc-card">
                       <CardHeader title="Select Year" />
                        <FormControl sx={{width:"100%",background:"#fff"}}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={10}
                          >
                            <MenuItem value={10}>Year</MenuItem>
                            <MenuItem value={20}>2022</MenuItem>
                            <MenuItem value={30}>2023</MenuItem>
                            
                          </Select>

                        </FormControl> 
                        </Box>
                        </Col>
             
                        
                
                        <Col xl={6}>
                        <Box className="mc-card">
                       <CardHeader title="Select Grade" />
                        <FormControl sx={{width:"100%",background:"#fff"}}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={10}
                          >
                            <MenuItem value={10}>Grade</MenuItem>
                            <MenuItem value={20}>10</MenuItem>
                            <MenuItem value={30}>11</MenuItem>
                            
                          </Select>

                        </FormControl> 
                        </Box>
                        </Col>

                        <Col xl={6}>
                        <Box className="mc-card" style={{minHeight:"9rem"}}>
                        <CardHeader title="Students Count" />
                        <p>2000</p>
                        </Box>
                        </Col>

                        <Col xl={6}>
                        <Box className="mc-card">
                        <CardHeader title="Students Attendance" />
                        <p>84.54%</p>
                        </Box>
                        </Col>
                        
                        <Col xl={12}>
                        <ClientsCard
                        title={data?.clients.title}
                        dotsMenu={data?.clients.dotsMenu}
                        table={data?.clients.table}
                        />
                         </Col>
                       
                    </Row>
              
                </Col>

                <Col xl={4}>
                <Row xs={1} lg={1} xl={1}>
                    <Col xl={12}>
                    <OrdersCard
                        title={data?.orders.title}
                        dotsMenu={data?.orders.dotsMenu}
                        items={data?.orders.items}
                    />
                    </Col>
                    
                    <Col xl={12}>
                    <RevenueCard
                    style={{minHeight:"20rem"}}
                        title={data?.revenue.title}
                        field={data?.revenue.field}
                        report={data?.revenue.report}
                        chart={data?.revenue.chart}
                    />
                    </Col>

                </Row>
            
                </Col>
                <Col xl={4}>
                    <OrdersCard
                        title={data?.orders.title}
                        dotsMenu={data?.orders.dotsMenu}
                        items={data?.orders.items}
                    />
                </Col>
                {/* <Col xl={12}>
                    <ProductsCard
                        title={data?.products.title}
                        dotsMenu={data?.products.dotsMenu}
                        table={data?.products.table}
                    />
                </Col>
                <Col xl={8}>
                    <RevenueCard
                        title={data?.revenue.title}
                        field={data?.revenue.field}
                        report={data?.revenue.report}
                        chart={data?.revenue.chart}
                    />
                </Col>
                <Col xl={4}>
                    <OrdersCard
                        title={data?.orders.title}
                        dotsMenu={data?.orders.dotsMenu}
                        items={data?.orders.items}
                    />
                </Col> */}
              
                {/* <Col xl={6}>
                    <ActivityCard
                        title={data?.activity.title}
                        dotsMenu={data?.activity.dotsMenu}
                        items={data?.activity.items}
                    />
                </Col> */}
            </Row>
        </SchoolPageLayout>
        </>
        : <Navigate to="/login" replace={true} />}
        </>
    );
}
