import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { Modal, ModalClose, Sheet, Typography } from "@mui/joy";

export default function SchoolsList() {
  const [schools, setSchools] = useState([]);
  const [callApi, setCallApi] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState("");
  const [warning, setWarning] = useState({
    text: "",
    status: "",
  });
  const [openWarning, setOpenWarning] = useState(false);
  const [selectedSchoolActive, setSelectedSchoolActive] = useState(0);
  const fetchSchools = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}school/get-all-schools`)
      .then((res) => {
        setSchools(res.data.Schools);
      })
      .catch((e) => {
        setSchools([]);
      });
  };

  const changeStatus = async (school_id, active) => {
    let FinalStatus;
    if (active === 0) {
      FinalStatus = 1;
    } else {
      FinalStatus = 0;
    }
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}school/change-status`, {
        id: school_id,
        active: FinalStatus,
      })
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {
      });
  };

  const deleteSchool = async (school_id) => {
    await axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}school/delete-school/${school_id}`
      )
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {
      });
  };
  const school_data = {
    columns: [
      {
        label: "S.no",
        field: "s_no",
        sort: "asc",
        width: 150,
      },
      {
        label: "School Name",
        field: "school_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Board",
        field: "board",
        sort: "asc",
        width: 150,
      },
      {
        label: "School Email",
        field: "school_email",
        sort: "asc",
        width: 150,
      },
      {
        label: "School Phone",
        field: "school_phone",
        sort: "asc",
        width: 150,
      },
      {
        label: "Contact First Name",
        field: "contact_fname",
        sort: "asc",
        width: 150,
      },
      {
        label: "Contact Last Name",
        field: "contact_lname",
        sort: "asc",
        width: 150,
      },
      {
        label: "Contact Email",
        field: "contact_email",
        sort: "asc",
        width: 150,
      },
      {
        label: "Contact Phone",
        field: "contact_phone",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: schools?.map((school, index) => {
      return {
        s_no: index + 1,
        school_name: school.name,
        board: school.board.name,
        school_email: school.email,
        school_phone: school.phone,
        contact_fname: school.contact.first_name,
        contact_lname: school.contact.last_name,
        contact_email: school.contact.email,
        contact_phone: school.contact.phone,
        actions: (
          <td
            style={{ display: "flex", justifyContent: "start", width: "100%" }}
          >
            <Link to="/edit-school" state={{ School: school }}>
              <button
                type="button"
                style={{
                  width: "2rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "1.6rem",
                }}
                className="btn btn-success"
              >
                <i className="fas fa-edit"></i>
              </button>
            </Link>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setSelectedSchool({ id: school._id });
                setSelectedSchoolActive(school.active);
                setWarning({
                  text: `Do you want to change the status to ${
                    school.active === 0 ? `Active?` : `Inactive?`
                  }`,
                  type: "status",
                });
              }}
              style={{
                width: "4rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className={
                school.active === 0 ? `btn btn-danger` : `btn btn-success`
              }
            >
              {school.active === 0 ? `Inactive` : `Active`}
            </button>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setWarning({
                  text: `Do you want to delete the school?`,
                  type: "delete",
                });
                setSelectedSchool({ name: school.name, id: school._id });
              }}
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="btn btn-danger"
            >
              <i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      };
    }),
  };

  useEffect(() => {
    fetchSchools();
  }, [callApi]);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Breadcrumb title={"Schools List"}></Breadcrumb>
            <Link to="/add-school">
              <Button className="btn btn-primary">ADD SCHOOL</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={12}>
                <Box className="mc-card">
                  <CardHeader title="Schools List" />
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={school_data}
                          style={{
                            marginTop: "1rem",
                            display: "block",
                            overflowX: "auto",
                            whiteSpace: "nowrap",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
              {/*
                            <Col>
                                <DivideTitle title="large size" className="mb-4 mt-3" />
                                <LabelField label="text field" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" />
                                <LabelField label="select option" fieldSize="w-100 h-lg" labelDir="label-col mb-4" option={["admin", "member", "client"]} />
                                <LabelTextarea label="long textarea" fieldSize="w-100 h-text-lg" labelDir="label-col"  />
                            </Col> */}
            </Row>
          </Box>
        </Col>

        {/* Warning Board Modal */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openWarning}
          onClose={() => setOpenWarning(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "30%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={5}
            >
              {warning.text}
            </Typography>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2rem",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (warning.type === "status") {
                    changeStatus(selectedSchool.id, selectedSchoolActive);
                    setOpenWarning(false);
                  } else if (warning.type === "delete") {
                    deleteSchool(selectedSchool.id, selectedSchool.name);
                    setOpenWarning(false);
                  }
                }}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-success"
              >
                Yes
              </button>
              <button
                onClick={() => setOpenWarning(false)}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-danger"
              >
                No
              </button>
            </div>
          </Sheet>
        </Modal>
      </Row>
    </PageLayout>
  );
}
