import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { Link, useNavigate } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import parse from "html-react-parser";

import { Modal, ModalClose, Sheet, Typography } from "@mui/joy";

export default function QuestionBank() {
  const [questions, setQuestions] = useState([]);

  const token = localStorage.getItem("token");

  const [selectedQuestions, setSelectedQuestions] = useState([]);

  const navigate = useNavigate();
  const [callApi, setCallApi] = useState(false);

  const [filters, setFilters] = useState({
    board: "",
    grade: "",
    subject: "",
    chapter: "",
  });

  const [boards, setBoards] = useState([]);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [chapters, setChapters] = useState([]);

  const [warning, setWarning] = useState({
    text: "",
    status: "",
  });

  const [openWarning, setOpenWarning] = useState(false);

  const [selectedQuestion, setSelectedQuestion] = useState("");

  const fetchBoards = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}board/get-all-boards`)
      .then((res) => {
        setBoards(res.data.Boards);
      })
      .catch((e) => {});
  };

  const fetchGrades = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}grade/get-all-grades`;
    const queryParameters = Object.entries(filters)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    if (queryParameters) {
      url += `?${queryParameters}`;
    }
    await axios
      .get(url)
      .then((res) => {
        setGrades(res.data.Grades);
      })
      .catch((e) => {
        setGrades([]);
      });
  };

  const fetchSubjects = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}subject/get-all-subjects`;
    const queryParameters = Object.entries(filters)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    if (queryParameters) {
      url += `?${queryParameters}`;
    }
    await axios
      .get(url)
      .then((res) => {
        setSubjects(res.data.subjects);
      })
      .catch((e) => {
        setSubjects([]);
      });
  };

  const fetchChapters = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}chapter/chapters`;
    const queryParameters = Object.entries(filters)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    if (queryParameters) {
      url += `?${queryParameters}`;
    }

    await axios
      .get(url)
      .then((res) => {
        setChapters(res.data);
      })
      .catch((e) => {
        setChapters([]);
      });
  };

  const fetchQuestions = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}question/get-questions`;

    const queryParameters = Object.entries(filters)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    if (queryParameters) {
      url += `?${queryParameters}`;
    }
    await axios
      .get(url, { headers: { jwt: token } })
      .then((res) => {
        setQuestions(res.data.Questions);
      })
      .finally(() => {})
      .catch((e) => {
        localStorage.clear();
        navigate("/login");
      });
  };

  const handleCheckboxChange = (event, question) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add the question to the selectedQuestions array
      setSelectedQuestions((prevSelectedQuestions) => [
        ...prevSelectedQuestions,
        question,
      ]);
    } else {
      // Remove the question from the selectedQuestions array
      setSelectedQuestions((prevSelectedQuestions) =>
        prevSelectedQuestions.filter(
          (selectedQuestion) => selectedQuestion !== question
        )
      );
    }
  };

  const fetchAllQuestions = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}question/get-questions`;

    await axios
      .get(url, { headers: { jwt: token } })
      .then((res) => {
        setQuestions(res.data.Questions);
      })
      .finally(() => {})
      .catch((e) => {
        localStorage.clear();
        navigate("/login");
      });
  };

  const deleteQuestion = async (question) => {
    await axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}question/questions/${question._id}`
      )
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {});
  };

  const questions_data = {
    columns: [
      {
        label: (
          <>
            S.no&nbsp; <input type="checkbox"></input>
          </>
        ),
        field: "s_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Question Details",
        field: "question",
        sort: "asc",
        width: 150,
      },
      {
        label: "Board Name",
        field: "board_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Grade Name",
        field: "grade_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Subject",
        field: "subject",
        sort: "asc",
        width: 150,
      },
      {
        label: "Chapter",
        field: "chapter",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: questions.map((question, index) => {
      return {
        s_no: (
          <th scope="row">
            <input
              type="checkbox"
              checked={selectedQuestions.includes(question)}
              onChange={(event) => handleCheckboxChange(event, question)}
            />
            &nbsp;{index + 1}
          </th>
        ),
        question: parse(question?.question),
        board_name: question?.boards?.name,
        subject: question?.subjects?.name,
        grade_name: question?.grades?.name,
        chapter: question?.chapters?.name,
        actions: (
          <td
            style={{ display: "flex", justifyContent: "start", width: "100%" }}
          >
            <Link to="/edit-question" state={{ Question: question }}>
              <button
                type="button"
                style={{
                  width: "2rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "1.6rem",
                }}
                className="btn btn-success"
              >
                <i className="fas fa-edit"></i>
              </button>
            </Link>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setWarning({
                  text: `Do you want to delete the Question?`,
                  type: "delete",
                });
                setSelectedQuestion(question);
              }}
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="btn btn-danger"
            >
              <i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      };
    }),
  };

  useEffect(() => {
    fetchBoards();
    if (boards.length > 0) {
      fetchGrades();
      if (grades.length > 0) {
        fetchSubjects();
        if (subjects.length > 0) {
          fetchChapters();
        }
      }
    }
    fetchQuestions();
  }, [callApi]);

  useEffect(() => {
    fetchAllQuestions();
  }, []);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Breadcrumb title={"Question Bank"}></Breadcrumb>
            <Link to="/add-question">
              <Button className="btn btn-primary">Add Question</Button>
            </Link>
          </div>
        </Col>

        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Select Board" />
                  <FormControl sx={{ width: 150 }}>
                    <InputLabel id="demo-simple-select-label">Board</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Board"
                      value={filters.board}
                      onChange={(e) => {
                        setFilters({ ...filters, board: e.target.value });
                        setCallApi(!callApi);
                      }}
                    >
                      {boards.length > 0 &&
                        boards.map((board) => {
                          return (
                            <MenuItem value={board?._id}>
                              {board?.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Select Grade" />
                  <FormControl sx={{ width: 150 }}>
                    <InputLabel id="demo-simple-select-label">Grade</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      label="Grade"
                      id="demo-simple-select"
                      value={filters.grade}
                      onChange={(e) => {
                        setFilters({ ...filters, grade: e.target.value });
                        setCallApi(!callApi);
                      }}
                    >
                      <MenuItem value={""}>None</MenuItem>
                      {grades.length > 0 &&
                        grades.map((grade) => {
                          return (
                            <MenuItem value={grade._id}>
                              {grade?.board?.name} - {grade?.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Select Subject" />
                  <FormControl sx={{ width: 150 }}>
                    <InputLabel id="demo-simple-select-label">
                      Subject
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      label="Subject"
                      id="demo-simple-select"
                      value={filters.subject}
                      onChange={(e) => {
                        setFilters({ ...filters, subject: e.target.value });
                        setCallApi(!callApi);
                      }}
                    >
                      <MenuItem value={""}>None</MenuItem>
                      {subjects.length > 0 &&
                        subjects.map((subject) => {
                          return (
                            <MenuItem value={subject._id}>
                              {subject?.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Select Chapter" />
                  <FormControl sx={{ width: 150 }}>
                    <InputLabel id="demo-simple-select-label">
                      Chapter
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      label="Chapter"
                      id="demo-simple-select"
                      value={filters.chapter}
                      onChange={(e) => {
                        setFilters({ ...filters, chapter: e.target.value });
                        setCallApi(!callApi);
                      }}
                    >
                      <MenuItem value={""}>None</MenuItem>
                      {chapters.length > 0 &&
                        chapters.map((chapter) => {
                          return (
                            <MenuItem value={chapter._id}>
                              {chapter?.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={2}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    alignItems: "center",
                    marginTop: "5.25rem",
                  }}
                >
                  <Button
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      fetchQuestions();
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </Col>

              <Col xl={12}>
                <Box className="mc-card">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "1rem",
                      marginRight: "2.2rem",
                    }}
                  >
                    <CardHeader title="Questions List" />
                    <Link to="/add-test">
                      <Button className="btn btn-primary">
                        Import Question
                      </Button>
                    </Link>
                  </div>
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={questions_data}
                          style={{ marginTop: "1rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>
        </Col>

        {/* Warning Question Modal */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openWarning}
          onClose={() => setOpenWarning(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "30%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={5}
            >
              {warning.text}
            </Typography>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2rem",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  // if(warning.type === "status") {
                  //   changeStatus(selectedStudent);
                  //   setOpenWarning(false);
                  // }
                  if (warning.type === "delete") {
                    deleteQuestion(selectedQuestion);
                    setOpenWarning(false);
                  }
                }}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-success"
              >
                Yes
              </button>
              <button
                onClick={() => setOpenWarning(false)}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-danger"
              >
                No
              </button>
            </div>
          </Sheet>
        </Modal>
      </Row>
    </PageLayout>
  );
}
