import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb, DivideTitle } from "../../components";
import { CardHeader } from "../../components/cards";
import { LabelField, IconField, LabelTextarea } from "../../components/fields";
import { Box, Item, Anchor, Text, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import {
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
} from "../../components/elements/Table";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import TextField from "@mui/material/TextField";
import dayjs from "dayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AddSubectArea() {
  const navigate = useNavigate();

  const [value, setValue] = React.useState(dayjs(new Date()));

  const [subject, setSubject] = useState("");

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const Success = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const Error = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const addSubjectArea = async () => {
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}subject-area/add-subject-area`, {
        subject,
      })
      .then((res) => {
        Success("Subject Area Added Successfully !");
        navigate("/subject-area-list");
      })
      .catch((e) => {
        Error(e.response.data.message);
      });
  };

  return (
    <PageLayout>
      <ToastContainer autoClose={1000} />
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Breadcrumb title={"Add Subject Areas"}></Breadcrumb>
            <Link to="/subject-area-list">
              <Button className="btn btn-primary">View Subject Areas</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={3}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Subject Area Name" />
                  <TextField
                    id="outlined-required"
                    value={subject}
                    placeholder="Enter Subject Area Name"
                    sx={{ width: "100%" }}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </div>
              </Col>

              <Col xl={3}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    alignItems: "center",
                    marginTop: "5.25rem",
                  }}
                >
                  <Button className="btn btn-primary" onClick={addSubjectArea}>
                    Add
                  </Button>
                </div>
              </Col>

              {/*
                            <Col>
                                <DivideTitle title="large size" className="mb-4 mt-3" />
                                <LabelField label="text field" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" />
                                <LabelField label="select option" fieldSize="w-100 h-lg" labelDir="label-col mb-4" option={["admin", "member", "client"]} />
                                <LabelTextarea label="long textarea" fieldSize="w-100 h-text-lg" labelDir="label-col"  />
                            </Col> */}
            </Row>
          </Box>
        </Col>
      </Row>
    </PageLayout>
  );
}
