import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";

import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { Modal, ModalClose, Sheet, Typography } from "@mui/joy";

export default function ChaptersList() {
  const [chapters, setChapters] = useState([]);
  const [callApi, setCallApi] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState("");
  const [warning, setWarning] = useState({
    text: "",
    status: "",
  });

  const [openWarning, setOpenWarning] = useState(false);
  const [selectedChapterActive, setSelectedChapterActive] = useState(0);



  const [boards, setBoards] = useState([]);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const fetchGrades = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}grade/get-all-grades`;
    const queryParameters = Object.entries(filters)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    if (queryParameters) {
      url += `?${queryParameters}`;
    }
    await axios
      .get(url)
      .then((res) => {
        setGrades(res.data.Grades);
      })
      .catch((e) => {
        setGrades([]);
      });
  };

  const fetchBoards = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}board/get-all-boards`)
      .then((res) => {
        setBoards(res.data.Boards);
      })
      .catch((e) => {});
  };

  const fetchSubjects = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}subject/get-all-subjects`;
    const queryParameters = Object.entries(filters)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    if (queryParameters) {
      url += `?${queryParameters}`;
    }
    await axios
      .get(url)
      .then((res) => {
        setSubjects(res.data.subjects);
      })
      .catch((e) => {
        setSubjects([]);
      });
  };

  const [filters, setFilters] = useState({
    board: "",
    grade: "",
    subject: "",
  });

  useEffect(() => {
    fetchBoards();
    if (boards.length > 0) {
      fetchGrades();
      if (grades.length > 0) {
        fetchSubjects();
        if (subjects.length > 0) {
          fetchFilterChapter();
        }
      }
    }
    fetchFilterChapter();
  }, [callApi]);
  const changeStatus = async (chapter_id, active) => {
    let FinalStatus;
    if (active === 0) {
      FinalStatus = 1;
    } else {
      FinalStatus = 0;
    }
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}chapter/change-status`, {
        id: chapter_id,
        active: FinalStatus,
      })
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {
      });
  };

  const deleteChapter = async (chapter_id) => {
    await axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}chapter/delete-chapter/${chapter_id}`
      )
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {
      });
  };

  const fetchFilterChapter = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}chapter/chapters`;

    const queryParameters = Object.entries(filters)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    if (queryParameters) {
      url += `?${queryParameters}`;
    }

    await axios
      .get(url)
      .then((res) => {
        setChapters(res.data);
      })
      .finally(() => {});
  };

  const chapters_data = {
    columns: [
      {
        label: "S.no",
        field: "s_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Chapter Name",
        field: "chapter_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Subject Name",
        field: "subject",
        sort: "asc",
        width: 150,
      },
      {
        label: "Board Name",
        field: "board",
        sort: "asc",
        width: 150,
      },
      {
        label: "Grade Name",
        field: "grade",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: chapters?.map((chapter, index) => {
      return {
        s_no: index + 1,
        chapter_name: chapter.name,
        board: chapter.board.name,
        grade: chapter.grade.name,
        subject: chapter.subject.name,
        actions: (
          <td
            style={{ display: "flex", justifyContent: "start", width: "100%" }}
          >
            <Link to="/edit-chapter" state={{ Chapter: chapter }}>
              <button
                type="button"
                style={{
                  width: "2rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "1.6rem",
                }}
                className="btn btn-success"
              >
                <i className="fas fa-edit"></i>
              </button>
            </Link>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setSelectedChapter({ id: chapter._id });
                setSelectedChapterActive(chapter.active);
                setWarning({
                  text: `Do you want to change the status to ${
                    chapter.active === 0 ? `Active?` : `Inactive?`
                  }`,
                  type: "status",
                });
              }}
              style={{
                width: "4rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className={
                chapter.active === 0 ? `btn btn-danger` : `btn btn-success`
              }
            >
              {chapter.active === 0 ? `Inactive` : `Active`}
            </button>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setWarning({
                  text: `Do you want to delete the chapter?`,
                  type: "delete",
                });
                setSelectedChapter({ name: chapter.name, id: chapter._id });
              }}
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="btn btn-danger"
            >
              <i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      };
    }),
  };

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Breadcrumb title={"Chapters List"}></Breadcrumb>
            <Link to="/add-chapter">
              <Button className="btn btn-primary">Add Chapters</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={3}>
                <CardHeader title="Board" />
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">Board</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Board"
                    value={filters.board}
                    onChange={(e) => {
                      setFilters({ ...filters, board: e.target.value });
                      setCallApi(!callApi);
                    }}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {boards &&
                      boards.map((items, index) => (
                        <MenuItem key={index} value={items._id}>
                          {items.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Col>
              <Col xl={3}>
                <CardHeader title="Grade" />
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">Grade</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Grade"
                    value={filters.grade}
                    onChange={(e) => {
                      setFilters({ ...filters, grade: e.target.value });
                      setCallApi(!callApi);
                    }}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {grades &&
                      grades.map((items, index) => (
                        <MenuItem key={index} value={items._id}>
                          {items.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Col>
              <Col xl={3}>
                <CardHeader title="Subject" />
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">Subject</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Subject"
                    value={filters.subject}
                    onChange={(e) => {
                      setFilters({ ...filters, subject: e.target.value });
                      setCallApi(!callApi);
                    }}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {subjects &&
                      subjects.map((items, index) => (
                        <MenuItem key={index} value={items._id}>
                          {items.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Col>

              <Col xl={1}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    alignItems: "center",
                    marginTop: "3.25rem",
                  }}
                >
                  <Button
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      fetchFilterChapter();
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </Col>

              <Col xl={12}>
                <Box className="mc-card">
                  <CardHeader title="Chapters List" />
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={chapters_data}
                          style={{ marginTop: "1rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>
        </Col>

        {/* Warning Board Modal */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openWarning}
          onClose={() => setOpenWarning(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "30%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={5}
            >
              {warning.text}
            </Typography>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2rem",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (warning.type === "status") {
                    changeStatus(selectedChapter.id, selectedChapterActive);
                    setOpenWarning(false);
                  } else if (warning.type === "delete") {
                    deleteChapter(selectedChapter.id, selectedChapter.name);
                    setOpenWarning(false);
                  }
                }}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-success"
              >
                Yes
              </button>
              <button
                onClick={() => setOpenWarning(false)}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-danger"
              >
                No
              </button>
            </div>
          </Sheet>
        </Modal>
      </Row>
    </PageLayout>
  );
}
