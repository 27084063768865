import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import TextField from "@mui/material/TextField";

import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoaderProvider } from "../../context/Preloader";
import axios from "axios";
import { useEffect } from "react";

export default function AddSubject() {
  const navigate = useNavigate();

  const [boards, setBoards] = useState("");
  const [grades, setGrades] = useState("");

  const [loading, setLoading] = useState(false);

  const [subject, setSubject] = useState({
    subject: "",
    grade: "",
    board: "",
  });

  const Success = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const Error = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const fetchBoards = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}board/get-all-boards`)
      .then((res) => {
        setBoards(res.data.Boards);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((e) => {
        setBoards([]);
      });
  };

  const fetchGrades = async () => {
    if (subject.board) {
      await axios
        .get(
          `${process.env.REACT_APP_BASE_URL}grade/get-grades-by-board/${subject.board}`
        )
        .then((res) => {
          setGrades(res.data.grades);
        })
        .catch((e) => {
          setGrades([]);
        });
    }
  };

  const addSubject = async () => {
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}subject/add-subject`, {
        subject: subject.subject,
        grade: subject.grade,
        board: subject.board,
      })
      .then((res) => {
        Success("Subject Added Successfully !");
        navigate("/subjects-list");
      })
      .catch((e) => {
        Error(e.response.data.message);
      });
  };

  useEffect(() => {
    fetchBoards();
  }, []);

  useEffect(() => {
    fetchGrades();
  }, [subject.board]);

  return (
    <>
      {!loading ? (
        <>
          <PageLayout>
            <ToastContainer autoClose={1000} />
            <Row>
              <Col xl={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Breadcrumb title={"Add Subject"}></Breadcrumb>
                  <Link to="/subjects-list">
                    <Button className="btn btn-primary">View Subjects</Button>
                  </Link>
                </div>
              </Col>
              <Col xl={12}>
                <Box className="mc-card">
                  <Row xs={1} md={1} xl={1}>
                    <Col xl={3}>
                      <div style={{ marginTop: "2rem" }}>
                        <CardHeader title="Board Name" />
                        <FormControl sx={{ width: "100%" }}>
                          <InputLabel id="demo-simple-select-label">
                            Board
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Board"
                            value={subject.board}
                            onChange={(e) =>
                              setSubject({ ...subject, board: e.target.value })
                            }
                          >
                            {boards.length > 0 &&
                              boards.map((board) => {
                                return (
                                  <MenuItem key={board._id} value={board._id}>
                                    {board.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </div>
                    </Col>

                    <Col xl={3}>
                      <div style={{ marginTop: "2rem" }}>
                        <CardHeader title="Grade" />
                        <FormControl sx={{ width: "100%" }}>
                          <InputLabel id="demo-simple-select-label">
                            Grade
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Grade"
                            value={subject.grade}
                            disabled={subject.board ? false : true}
                            onChange={(e) =>
                              setSubject({ ...subject, grade: e.target.value })
                            }
                          >
                            {grades.length > 0 &&
                              grades.map((grade) => {
                                return (
                                  <MenuItem key={grade._id} value={grade._id}>
                                    {grade.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </div>
                    </Col>

                    <Col xl={3}>
                      <div style={{ marginTop: "2rem" }}>
                        <CardHeader title="Subject Name" />
                        <TextField
                          id="outlined-required"
                          defaultValue=""
                          sx={{ width: "100%" }}
                          disabled={subject.grade ? false : true}
                          onChange={(e) =>
                            setSubject({ ...subject, subject: e.target.value })
                          }
                          placeholder="Enter Subject Name"
                        />
                      </div>
                    </Col>

                    <Col xl={3}>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "start",
                          alignItems: "center",
                          marginTop: "5.25rem",
                        }}
                      >
                        <Button
                          className="btn btn-primary"
                          onClick={(e) => {
                            e.preventDefault();
                            addSubject();
                          }}
                        >
                          Add
                        </Button>
                      </div>
                    </Col>

                    {/*
                            <Col>
                                <DivideTitle title="large size" className="mb-4 mt-3" />
                                <LabelField label="text field" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" />
                                <LabelField label="select option" fieldSize="w-100 h-lg" labelDir="label-col mb-4" option={["admin", "member", "client"]} />
                                <LabelTextarea label="long textarea" fieldSize="w-100 h-text-lg" labelDir="label-col"  />
                            </Col> */}
                  </Row>
                </Box>
              </Col>
            </Row>
          </PageLayout>
        </>
      ) : (
        <LoaderProvider loading={loading} />
      )}
    </>
  );
}
