import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Anchor } from "../elements";

export default function Logout({ data }) {
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const logout = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}admin/logout`, {
        headers: { jwt: token },
      })
      .then((res) => {
        localStorage.clear();
      })
      .finally(() => {
        navigate("/login");
      })
      .catch((e) => {
        alert(e);
      });
  };
  return (
    <Box className="mc-sidebar-logout text-center">
      <Anchor
        onClick={logout}
        icon={data?.icon}
        text={data?.text}
        className="mc-btn primary sm"
      />
    </Box>
  );
}
