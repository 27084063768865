import React from 'react'
import { Row, Col } from "react-bootstrap";
import { Breadcrumb, DivideTitle } from "../../components";
import { CardHeader } from "../../components/cards";
import PageLayout from "../../layouts/PageLayout";
import { Box, Item, Anchor, Text,Button } from "../../components/elements";
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';


function CounsellingTypesList() {

  const counselling_types_data = {
    columns: [
      {
        label: 'S.no',
        field: 's_no',
        sort: 'asc',
        width: 50
      },
      {
        label: 'Counselling Name',
        field: 'name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Actions',
        field: 'actions',
        sort: 'asc',
        width: 150
      }
    ],
    rows: [
      {
        s_no : '1',
        name: 'One to One Counselling',
        actions:
        <td style={{display:"flex",justifyContent:"start",width:"100%"}}>
          <button type="button" style={{width:"2rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-success"><i className="fas fa-edit"></i></button>
          <button type="button" style={{width:"4rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-danger">Inactive</button>
          <button type="button" style={{width:"2rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center"}} className="btn btn-danger"><i className="fas fa-trash"></i></button>
        </td>
      },
      {
        s_no : '2',
        name: 'GrowSharp Counselling',
        actions:
        <td style={{display:"flex",justifyContent:"start",width:"100%"}}>
          <button type="button" style={{width:"2rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-success"><i className="fas fa-edit"></i></button>
          <button type="button" style={{width:"4rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-danger">Inactive</button>
          <button type="button" style={{width:"2rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center"}} className="btn btn-danger"><i className="fas fa-trash"></i></button>
        </td>
      }
    ]
  };

  return (
    <>
      <PageLayout>
            <Row>
                <Col xl={12}>
                    <Breadcrumb title={ "Counselling Types List" }>
                    <Link to="/add-counselling-type"><Button className="btn btn-primary">Add Counselling Type</Button></Link>
                    </Breadcrumb>            
                </Col>
                <Col xl={12}>
                    <Box className="mc-card">
                        <Row xs={1} md={1} xl={1}>


                                
                             <Col xl={12}>

                             <Box className="mc-card">
                             <CardHeader title="Counselling Types List" />
                             <div className="container">
                              <div className="row">
                                <div className="col-12">
                                <MDBDataTable
                                  striped
                                  bordered
                                  small
                                  data={counselling_types_data}
                                  style={{marginTop:"1rem"}}
                                  />
                                  {/* <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th scope="col">S.no</th>
                                        <th scope="col">Counselling Name</th>
                                        <th scope="col">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <th scope="row">&nbsp; 1</th>
                                        
                                        <td>
                                        One to One Counselling
                                        </td>

                                       
                                        <td style={{display:"flex",justifyContent:"start"}}>
                                          <button type="button" style={{width:"2rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-success"><i className="fas fa-edit"></i></button>
                                          <button type="button" style={{width:"4rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-danger">Inactive</button>
                                          <button type="button" style={{width:"2rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center"}} className="btn btn-danger"><i className="fas fa-trash"></i></button>
                                          
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row">&nbsp; 2</th>
                                        
                                        <td>
                                        GrowSharp Counselling
                                        </td>

                                       
                                        <td style={{display:"flex",justifyContent:"start"}}>
                                          <button type="button" style={{width:"2rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-success"><i className="fas fa-edit"></i></button>
                                          <button type="button" style={{width:"4rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-danger">Inactive</button>
                                          <button type="button" style={{width:"2rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center"}} className="btn btn-danger"><i className="fas fa-trash"></i></button>
                                          
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table> */}
                                </div>
                              </div>
                            </div>
                                                        
                            </Box>
                            </Col>
                            {/*
                            <Col>
                                <DivideTitle title="large size" className="mb-4 mt-3" />
                                <LabelField label="text field" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" />
                                <LabelField label="select option" fieldSize="w-100 h-lg" labelDir="label-col mb-4" option={["admin", "member", "client"]} />
                                <LabelTextarea label="long textarea" fieldSize="w-100 h-text-lg" labelDir="label-col"  />
                            </Col> */}
                        </Row>
                    </Box>
                </Col>
            </Row>
        </PageLayout>
    </>
  )
}

export default CounsellingTypesList